import { graphql } from '@mp/graphql/generated';
import { useGraphQLQuery } from './useGraphQLRequest';
import { type ResultOf } from '@graphql-typed-document-node/core';

const ConditionsGql = graphql(`
  query Conditions($input: ConditionQueryInput) {
    conditions(input: $input) {
      id
      condition_type
      display_category
      label
      payload
      slug
      qualifications {
        id
        input_source
        instructions
        long_name
        short_name
        slug
        admin_view_only
        qualification_type
        status
        qualification_attributes {
          id
          name
          field_type
          required
          data
          input_source
          active
          slug
        }
        qualification_automations {
          id
          description
          name
          loading_text
          is_active
          qualification_automation_attributes {
            id
            qualification_attribute {
              id
              field_type
              slug
              name
              data
              input_source
              active
            }
            required
          }
        }
      }
    }
  }
`);

type ConditionsGqlResult = ResultOf<typeof ConditionsGql>;
type Condition = NonNullable<ConditionsGqlResult['conditions']>[number];

export type UseConditionsReturn = {
  conditions: Condition[];
  isLoading: boolean;
};

export const useConditions = (conditionIds: number[]): UseConditionsReturn => {
  const enabled = !!conditionIds.length;

  const { data: conditionsData, isLoading } = useGraphQLQuery<ConditionsGqlResult>({
    enabled,
    operationName: 'GetConditions',
    query: ConditionsGql,
    variables: {
      input: { filter: { id: conditionIds } },
    },
  });

  return {
    conditions: conditionsData?.conditions ?? [],
    isLoading,
  };
};
