import { IAccount } from '@medely/types';
import { isSaasAccount, isAgencyAccount } from './account';

export enum NotificationKey {
  SMS_NOTIFICATION = 'sms_notification',
  CLOCK_IN_OUT = 'clock_in_out',
  PER_DIEM_REC = 'per_diem_rec',
  SMS_NOTIFICATION_BULK = 'sms_notification_bulk',
  LOCAL_ASSIGNMENT_SMS_NOTIFICATION = 'local_assignment_sms_notification',
  ASSIGNMENT_SMS_NOTIFICATION = 'assignment_sms_notification',
  ASSIGNMENT_REC = 'assignment_rec',
  NEW_REQUIREMENT_FOR_SAAS_PRO = 'new_requirement_for_saas_pro',
}

export enum NotificationType {
  SMS = 'sms',
  EMAIL = 'email',
  PUSH = 'push',
}

export const NOTIFICATION_SWITCH_LABEL = {
  [NotificationType.SMS]: 'Text message',
  [NotificationType.EMAIL]: 'Email',
  [NotificationType.PUSH]: 'Push Notifications',
};

export type NotificationItem = {
  type: NotificationType;
  key: string;
};

export type NotificationSection = {
  label: string;
  items: NotificationItem[];
  description?: string;
};

export const pushNotificationSection: NotificationSection = {
  label: 'Clock In/Out Notifications',
  description: "Get notifications when it's time to clock in or out",
  items: [
    {
      type: NotificationType.PUSH,
      key: NotificationKey.CLOCK_IN_OUT,
    },
  ],
};

export const notificationSections: NotificationSection[] = [
  {
    label: 'Per diem shifts',
    description: 'Receive notifications when a new per diem shift that matches your profile.',
    items: [
      {
        type: NotificationType.SMS,
        key: NotificationKey.SMS_NOTIFICATION,
      },
      {
        type: NotificationType.EMAIL,
        key: NotificationKey.PER_DIEM_REC,
      },
    ],
  },
  {
    label: 'Daily per diem updates',
    description: 'Get a daily summary of new per diem shifts that may be a good fit for you.',
    items: [
      {
        type: NotificationType.SMS,
        key: NotificationKey.SMS_NOTIFICATION_BULK,
      },
    ],
  },
  {
    label: 'Local assignments',
    description: 'Receive notifications for new local assignments.',
    items: [
      {
        type: NotificationType.SMS,
        key: NotificationKey.LOCAL_ASSIGNMENT_SMS_NOTIFICATION,
      },
    ],
  },
  {
    label: 'Travel assignments',
    description: 'Get notified about new travel assignment opportunities that match your profile.',
    items: [
      {
        type: NotificationType.SMS,
        key: NotificationKey.ASSIGNMENT_SMS_NOTIFICATION,
      },
      {
        type: NotificationType.EMAIL,
        key: NotificationKey.ASSIGNMENT_REC,
      },
    ],
  },
];

export const saasNotificationSections: NotificationSection[] = [
  {
    label: 'Compliance',
    description: 'Receive emails to stay compliant with your facility.',
    items: [
      {
        type: NotificationType.EMAIL,
        key: NotificationKey.NEW_REQUIREMENT_FOR_SAAS_PRO,
      },
    ],
  },
];

export const getNotificationSections = (account: IAccount) => {
  const displayPush = Boolean(account?.firebase_notification_key);

  if (isSaasAccount(account)) {
    return saasNotificationSections;
  }

  const removeNotificationSectionBasedOnCondition = (
    notification: NotificationSection,
    labelToRemove: string,
    condition: boolean,
  ) => {
    if (notification.label === labelToRemove && condition) {
      return false;
    }

    return true;
  };

  if (displayPush) {
    return [
      pushNotificationSection,
      ...notificationSections.filter((notification) =>
        removeNotificationSectionBasedOnCondition(
          notification,
          'Travel assignments',
          isAgencyAccount(account),
        ),
      ),
    ];
  }

  return notificationSections.filter((notification) =>
    removeNotificationSectionBasedOnCondition(
      notification,
      'Travel assignments',
      isAgencyAccount(account),
    ),
  );
};
