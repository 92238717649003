import { sessionTools } from '@medely/base';
import { GooglePlacesLoaderProvider, isSaasAccount } from '@mp';
import { useAnalytics, useConfigurationContext } from '@medely/ui-kit';
import { Head } from '@medely/web-components';
import { useDeviceInfo } from '@medely/web-components/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useInviteRedirect } from '@src/components/auth/AcceptInvite/useInviteRedirect';
import MobileAuthLogin from '@src/components/auth/MobileAuthLogin';
import CenteredLoader from '@src/components/CenteredLoader';
import LocationRedirect from '@src/components/LocationRedirect';
import { WebViews } from '@src/components/WebViews/WebViews';
import configKeys from '@src/config';
import { pageTitles } from '@src/constants/pageTitles';
import useCurrentUser from '@src/hooks/useCurrentUser';
import useHandleError from '@src/hooks/useHandleError';
import useOnboarding from '@src/hooks/useOnboarding';
import moment from 'moment';
import React, { Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { BooleanParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { isSaasCredentialingAccount } from '@src/utils/account';
import { createRedirectSearch } from '@src/utils/createRedirectSearch';
import ProView from 'View';

type LazyRetryFunction = <T>(componentImport: () => Promise<T>, name: string) => Promise<T>;

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry: LazyRetryFunction = (componentImport, name) => {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false',
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
        resolve(component);
      })
      // if import fails, refresh the page automatically
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

const Login = lazy(() =>
  lazyRetry(
    () =>
      import('components/auth/index.ts').then(({ Login }) => ({
        default: Login,
      })),
    'login',
  ),
);
const SignUp = lazy(() =>
  lazyRetry(
    () =>
      import('components/auth/index.ts').then(({ SignUp }) => ({
        default: SignUp,
      })),
    'sign-up',
  ),
);

const AcceptInvite = lazy(() =>
  lazyRetry(
    () =>
      import('components/auth/index.ts').then(({ AcceptInvite }) => ({
        default: AcceptInvite,
      })),
    'invite',
  ),
);

const Create = lazy(() =>
  lazyRetry(
    () =>
      import('components/auth/index.ts').then(({ Create }) => ({
        default: Create,
      })),
    'create',
  ),
);

const SetPassword = lazy(() =>
  lazyRetry(
    () =>
      import('components/auth/index.ts').then(({ SetPassword }) => ({
        default: SetPassword,
      })),
    'set-password',
  ),
);

const ResetPassword = lazy(() =>
  lazyRetry(
    () =>
      import('components/auth/index.ts').then(({ ResetPassword }) => ({
        default: ResetPassword,
      })),
    'reset-password',
  ),
);
const ReferenceGateway = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ ReferenceGateway }) => ({
        default: ReferenceGateway,
      })),
    'reference',
  ),
);
const ApplicationRoot = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ ApplicationRoot }) => ({
        default: ApplicationRoot,
      })),
    'application',
  ),
);

const AssignmentsRoot = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ AssignmentsRoot }) => ({
        default: AssignmentsRoot,
      })),
    'assignments',
  ),
);

const AvailableRoot = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ AvailableRoot }) => ({
        default: AvailableRoot,
      })),
    'available',
  ),
);

const CredentialsRoot = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ CredentialsRoot }) => ({
        default: CredentialsRoot,
      })),
    'credentials',
  ),
);

const MyAssignmentsRoot = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ MyAssignmentsRoot }) => ({
        default: MyAssignmentsRoot,
      })),
    'my-assignments',
  ),
);

const EarningsRoot = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ EarningsRoot }) => ({
        default: EarningsRoot,
      })),
    'earnings',
  ),
);

const JobsRoot = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ JobsRoot }) => ({
        default: JobsRoot,
      })),
    'jobs',
  ),
);

const ProfileRoot = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ ProfileRoot }) => ({
        default: ProfileRoot,
      })),
    'profile',
  ),
);

const ScheduleRoot = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ ScheduleRoot }) => ({
        default: ScheduleRoot,
      })),
    'schedule',
  ),
);

const TimecardsRoot = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ TimecardsRoot }) => ({
        default: TimecardsRoot,
      })),
    'timecards',
  ),
);

const TodayRoot = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ TodayRoot }) => ({
        default: TodayRoot,
      })),
    'today',
  ),
);

const SettingsRoot = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ SettingsRoot }) => ({
        default: SettingsRoot,
      })),
    'settings',
  ),
);

const OnboardingRoot = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ OnboardingRoot }) => ({
        default: OnboardingRoot,
      })),
    'onboarding',
  ),
);

const ProPoolRoot = lazy(() =>
  lazyRetry(
    () =>
      import('routes/index.ts').then(({ ProPoolRoot }) => ({
        default: ProPoolRoot,
      })),
    'pro-pool',
  ),
);

const AppRouter = () => {
  const config = useConfigurationContext();
  const { isLoading: loadingUser, currentUser, isApplicant } = useCurrentUser();
  const {
    showOnboarding,
    hasAnOnboardedPosition,
    isAssignmentOnboardingRequired,
    assignmentsMissingConditions,
  } = useOnboarding();

  const url = new URL(window.location.href);
  const search = createRedirectSearch(url);

  const [{ webView, mobileToken }] = useQueryParams({
    mobileToken: StringParam,
    webView: BooleanParam,
  });

  const urlHasMobileAuthToken = !!mobileToken;

  const onError = useHandleError();
  const queryClient = useQueryClient();
  queryClient.setDefaultOptions({
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // queries going stale was messing up our multi-page forms
      // we can change this to something more aggressive, but we need
      // to be very careful about queries that populate form options
      staleTime: Infinity,
      onError,
    },
    mutations: {
      onMutate: (context) => context,
      onSettled: () => {
        queryClient.invalidateQueries();
      },
      onError,
    },
  });

  const analytics = useAnalytics();
  const deviceInfo = useDeviceInfo();
  const history = useHistory();
  const location = useLocation();

  const professionalType = (() => {
    if (currentUser?.company_id) {
      return 'irp';
    }
    if (currentUser?.agency_id) {
      return 'agency';
    }
    return 'marketplace';
  })();

  useEffect(() => {
    sessionTools.recordActivity(() =>
      analytics.track({
        context: 'Session',
        action: 'Started',
        session_access_type: 'web',
        pro_type: professionalType,
      }),
    );
  }, [location.pathname, location.search]);

  useEffect(() => {
    analytics.page('Professionals', location.pathname, { pro_type: professionalType });
  }, [location.pathname]);

  const followingQualificationRedirect = !!useQueryParam('credential', StringParam)[0];
  const ehrModalRedirect = useQueryParam('ehrModal', StringParam)[0];

  const initialRedirectForOnboarding = () => {
    if (
      currentUser &&
      currentUser.professional?.status === 'active' &&
      !webView &&
      !mobileToken &&
      !followingQualificationRedirect &&
      ehrModalRedirect !== 'open'
    ) {
      if (showOnboarding && !hasAnOnboardedPosition && !location.pathname.includes('/onboarding')) {
        history.push('/onboarding');
      } else if (
        !showOnboarding &&
        isSaasCredentialingAccount(currentUser) &&
        !location.pathname.includes('/credentials')
      ) {
        history.push('/credentials');
      } else if (isAssignmentOnboardingRequired && !location.pathname.includes('/onboarding')) {
        history.push(`/assignments/${assignmentsMissingConditions[0]}/onboarding`);
      }
    }
  };
  useEffect(initialRedirectForOnboarding, [
    currentUser?.id,
    showOnboarding,
    assignmentsMissingConditions.length,
  ]);
  useInviteRedirect();

  const identifyUser = () => {
    if (currentUser) {
      analytics.identify(
        currentUser.id.toString(),
        {
          name: currentUser.name,
          email: currentUser.email,
          hide_default_launcher: true,
          status: currentUser.professional?.status,
          application_status: currentUser.professional?.application_status,
          professional: true,
          companyID: currentUser.company_id,
          agencyID: currentUser.agency_id,
          pro_type: professionalType,
          professionalType,
        },
        {
          Intercom: { user_hash: currentUser.user_hash },
        },
      );

      analytics.track('Professional Login', {
        Date: moment().format('MM/DD/YYYY'),
        OperatingSystem: deviceInfo.current.operatingSystem,
        OperatingSystemVersion: deviceInfo.current.operatingSystemVersion,
        BrowserVersion: deviceInfo.current.browserVersion,
        Device: deviceInfo.current.device,
        pro_type: professionalType,
      });
    }
  };
  useEffect(identifyUser, [currentUser?.id]);

  if (webView) {
    return <WebViews />;
  }

  if (!!urlHasMobileAuthToken) {
    return <MobileAuthLogin />;
  }

  if (loadingUser) {
    return <CenteredLoader />;
  }

  if (!currentUser) {
    return (
      <Suspense fallback={<CenteredLoader />}>
        <Head>
          {/* default so the previous page title isn't displayed when the current page title is undefined */}
          <title>{pageTitles.default}</title>
        </Head>
        <Switch>
          <Route path={`${config.root}/login`} component={Login} />
          <Route path={`${config.root}/invite`} component={AcceptInvite} />
          <Route path={`${config.root}/sign-up`} component={SignUp} />
          <Route path={`${config.root}/create`} component={Create} />
          <Route path={`${config.root}/set-password`} component={SetPassword} />
          <Route path={`${config.root}/reset-password`} component={ResetPassword} />
          <Route path={`${config.root}/reference-gateway`} component={ReferenceGateway} />
          <Route
            path={`${config.root}/assignments_boards/:id`}
            component={(props) => <LocationRedirect {...props} host={config.landingsUrl} />}
          />
          <Redirect to={{ pathname: `${config.root}/login`, search: search }} />
        </Switch>
      </Suspense>
    );
  } else {
    return (
      <GooglePlacesLoaderProvider apiKey={configKeys.googleMapsKey}>
        <ProView>
          <Suspense fallback={<CenteredLoader />}>
            <Head>
              {/* default so the previous page title isn't displayed when the current page title is undefined */}
              <title>{pageTitles.default}</title>
            </Head>
            <Switch>
              <Route path={`${config.root}/pro-pool`} component={ProPoolRoot} />
              <Route path={`${config.root}/application`} component={ApplicationRoot} />
              <Route path={`${config.root}/assignments`} component={AssignmentsRoot} />
              <Route path={`${config.root}/available`} component={AvailableRoot} />
              <Route path={`${config.root}/credentials`} component={CredentialsRoot} />
              <Route path={`${config.root}/my-assignments`} component={MyAssignmentsRoot} />
              <Route path={`${config.root}/earnings`} component={EarningsRoot} />
              <Redirect
                from={`${config.root}/jobs/assignments`}
                to={{
                  pathname: `${config.root}/available/assignments/local`,
                  search: url.search,
                }}
              />
              <Redirect
                from={`${config.root}/available/local`}
                to={{
                  pathname: `${config.root}/available/jobs`,
                  search: url.search,
                }}
              />
              <Redirect
                from={`${config.root}/jobs/available`}
                to={{
                  pathname: `${config.root}/available/jobs`,
                  search: url.search,
                }}
              />
              <Route path={`${config.root}/jobs`} component={JobsRoot} />
              <Route path={`${config.root}/profile`} component={ProfileRoot} />
              <Route path={`${config.root}/schedule`} component={ScheduleRoot} />
              <Route
                path={`${config.root}/timesheets`}
                render={(props) =>
                  isSaasAccount(currentUser) ? <Redirect to={`/`} /> : <TimecardsRoot {...props} />
                }
              />
              <Route path={`${config.root}/settings`} component={SettingsRoot} />
              <Route path={`${config.root}/onboarding`} component={OnboardingRoot} />
              <Route
                path={`${config.root}/assignments_boards/:id`}
                component={(props) => <LocationRedirect {...props} host={config.landingsUrl} />}
              />
              <Redirect
                from={`${config.root}/dashboard/credentials`}
                to={!isApplicant ? `${config.root}/credentials` : `${config.root}/application`}
              />
              <Redirect from={`${config.root}/dashboard/*`} to={`/`} />
              <Redirect
                from={`${config.root}/dashboard`}
                to={
                  !isApplicant
                    ? `${config.root}/my-assignments/applications`
                    : `${config.root}/application`
                }
              />
              <Redirect
                from={`${config.root}/jobs-dashboard`}
                to={`${config.root}/my-assignments/applications`}
              />
              {!isApplicant && isSaasCredentialingAccount(currentUser) && (
                <Redirect from={config.root} to={`${config.root}/credentials`} />
              )}
              <Route path={`${config.root}`} component={TodayRoot} />
              <Redirect to={`${config.root}`} />
            </Switch>
          </Suspense>
        </ProView>
      </GooglePlacesLoaderProvider>
    );
  }
};

export default AppRouter;
