import { useMutation } from '@tanstack/react-query';
import { useNetworkRequest } from './useNetworkRequest';

export const useMaskedPhoneNumberMutations = () => {
  const { axios } = useNetworkRequest();
  const { mutate: requestJobMaskedNumber, isLoading: isJobLoading } = useMutation((jobId: number) =>
    axios.post(`/v3/professional/jobs/${jobId}/phone_number`),
  );
  const { mutate: requestAssignmentMaskedNumber, isLoading: isAssignmentLoading } = useMutation(
    (assignmentId: number) =>
      axios.post(`/v3/professional/assignments/${assignmentId}/phone_number`),
  );
  return {
    requestJobMaskedNumber,
    requestAssignmentMaskedNumber,
    isLoading: isJobLoading || isAssignmentLoading,
  };
};
