import { BottomSheetDialog } from '@medely/web-components';
import useDevice from '../../../../hooks/useDevice.web';
import React from 'react';
import { UnsavedChangesInfo } from './UnsavedChangesInfo';
import { Button } from '@medely/ui-kit';

type BottomSheetDialogProps = React.ComponentProps<typeof BottomSheetDialog>;

export type UnsavedChangesDialogProps = {
  open: BottomSheetDialogProps['open'];
  onClose: BottomSheetDialogProps['onClose'];
  onDiscardChangesClick: (params: undefined) => void;
};

export const UnsavedChangesDialog = ({
  open,
  onClose,
  onDiscardChangesClick,
}: UnsavedChangesDialogProps) => {
  const isDesktop = useDevice() === 'desktop';

  return (
    <BottomSheetDialog
      open={open}
      onClose={onClose}
      title="Unsaved changes?"
      disableRestoreFocus
      sx={{ alignSelf: isDesktop ? 'center' : 'end' }}
      content={<UnsavedChangesInfo />}
      actions={
        <Button
          fullWidth
          onClick={() => onDiscardChangesClick(undefined)}
          color="destructive"
          testId="unsaved-changes-discard-button"
        >
          Discard changes
        </Button>
      }
      isDesktop={isDesktop}
    />
  );
};
