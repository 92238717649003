import React from 'react';
import { Box, Heading, Text } from '@medely/ui-kit';
import { useProNavigate } from '@mp/contexts';

type JobAdditionalCredentialsProps = {
  isMobile?: boolean;
};

export const JobAdditionalCredentials = ({
  isMobile,
}: JobAdditionalCredentialsProps): React.ReactElement => {
  const { navigate } = useProNavigate();

  return (
    <Box data-testid="job-additional-credentials" borderRadius="8px" px={isMobile ? 2 : 0}>
      <Box mt={1}>
        <Heading size="s" color="text.primary">
          Additional Credentials
        </Heading>
      </Box>
      <Box mt={isMobile ? 1 : 2}>
        <Text size="m" color="text.secondary">
          This facility requires additional credentials to book this job.
        </Text>
      </Box>
      <Box
        mt={isMobile ? 1 : 2}
        onClick={() => {
          if (isMobile) {
            navigate('RootCredentials');
          } else {
            navigate('Credentials');
          }
        }}
      >
        <Text size={isMobile ? 'm' : 'l'} color="state.teal.primary">
          Add credentials
        </Text>
      </Box>
    </Box>
  );
};
