import React from 'react';
import { sumBy, uniqBy } from 'lodash';

import {
  Box,
  DesignTokenColor,
  HStack,
  Heading,
  InfoBadge,
  Label,
  List,
  Text,
  VStack,
} from '@medely/ui-kit';
import { useGate } from '../../../hooks/useGate';
import {
  JobBillingBreakdown,
  breakdownJobByRate,
  centsToCurrency,
  splitAmountsByStatus,
} from '../../../utils';
import DisplayHelpers from '../../../utils/displayHelpers';
import { IPayoutForPayoutListItem, PayoutListItem } from '../../earnings';
import { CompletedShiftPaymentInfoProps } from './interface';
import { UNPAID_ADJUSTMENT_JOB_STATUSES } from '../../../constants';
import { useProNavigate } from '../../../contexts/NavigationContext';
import { DescriptionListItem } from '../../../components/DescriptionListItem';
import useEarningsEvent from '../../../hooks/analytics/useEarningsEvent';
import { config } from '@mp/config';
import { gateNames } from '@mp/constants/statsig';
import { HelpCenterLink } from '@mp/components/helpCenterLink';

const { paymentStatusLabel } = DisplayHelpers.job;
const { taxableTypeAsTitle } = DisplayHelpers.expenseAdjustment;

const W2_ASSIGNMENT_LINK = config.help.paymentsForTravelAssignments;
const TEN99_ASSIGNMENT_LINK = config.help.paymentsFor99Assignments;
const PER_DIEM_LINK = config.help.paymentsForPerDiemShifts;

export const CompletedShiftPaymentInfo = ({ job: rawJob }: CompletedShiftPaymentInfoProps) => {
  const { value: isSplitPayEnabled } = useGate(gateNames.splitPay);
  const { navigate } = useProNavigate();
  const { trackJobPayoutListItemClicked } = useEarningsEvent();

  const job = getJobWithFilteredAdjustments(rawJob);
  const breakdown = breakdownJobByRate(job);

  const adjustmentItems = job.expense_adjustments.map((adjustment) => ({
    key: `${adjustment.id}`,
    label: taxableTypeAsTitle(adjustment.taxable_type),
    total: centsToCurrency(adjustment.amount_cents),
  }));
  const totalAmount =
    job.job_billing_sums.payout_total_amount_cents + sumBy(job.expense_adjustments, 'amount_cents');

  const paymentSplit = splitAmountsByStatus(
    formatJobBillings(job),
    job.expense_adjustments,
    isSplitPayEnabled,
  );
  const paymentSplitItems: { label: string; amount: number; color: DesignTokenColor }[] = [
    { label: 'Amount paid', amount: paymentSplit.payout, color: 'state.success.secondary' },
    { label: 'Amount pending', amount: paymentSplit.waitingForPayout, color: 'text.secondary' },
    { label: 'Amount in review', amount: paymentSplit.inReview, color: 'state.warning.secondary' },
    { label: 'Amount in dispute', amount: paymentSplit.disputed, color: 'state.error.secondary' },
  ];
  const { label: paymentStatus, color: statusColor } = paymentStatusLabel(job);

  const payouts = getPayoutsForSimplifiedList(job);

  // Currently assignments are paid weekly and per diems are MWF.
  // This will change in the future and this logic should change as well.
  const isWeeklyPay = !!job.assignment;
  const learnMoreUrl = !!job.assignment
    ? job.is_w2
      ? W2_ASSIGNMENT_LINK
      : TEN99_ASSIGNMENT_LINK
    : PER_DIEM_LINK;

  return (
    <VStack gap={2}>
      <VStack gap={1}>
        <Heading size="s">Earnings</Heading>
        <VStack>
          <List items={breakdown} keyFn={(item) => item.key} render={BreakdownListItem} />
          <List items={adjustmentItems} keyFn={(item) => item.key} render={BreakdownListItem} />
          <HStack
            borderTopWidth={1}
            borderColor="controls.backing+line"
            justifyContent="space-between"
            py={1}
          >
            <Text size="m" color="text.secondary">
              Total
            </Text>
            <Label size="m" textAlign="right" color="state.success.secondary">
              {centsToCurrency(totalAmount)}
            </Label>
          </HStack>
        </VStack>
      </VStack>
      <VStack gap={1}>
        <Heading size="s">Payment</Heading>
        <Box>
          <Text size="m" color="text.secondary">
            {isWeeklyPay
              ? 'Payments are sent weekly after you clock out. Your bank may take 24 to 72 hours for processing. '
              : 'Payments are sent on Mon, Wed, or Fri after you clock out. Your bank may take 24 to 72 hours for processing. '}
            <HelpCenterLink url={learnMoreUrl} text="Learn more." />
          </Text>
        </Box>
        <VStack>
          <List
            items={paymentSplitItems}
            keyFn={(item) => item.label}
            render={(item) =>
              !!item.amount ? (
                <DescriptionListItem
                  label={item.label}
                  value={
                    <Text size="m" color={item.color}>
                      {centsToCurrency(item.amount)}
                    </Text>
                  }
                />
              ) : null
            }
          />
          <HStack
            borderTopWidth={1}
            borderColor="controls.backing+line"
            justifyContent="space-between"
            py={1}
          >
            <Text size="m" color="text.secondary">
              Status
            </Text>
            <InfoBadge label={paymentStatus} color={statusColor} />
          </HStack>
        </VStack>
      </VStack>
      {payouts.length > 0 ? (
        <List
          showDivider
          items={payouts}
          keyFn={(payout) => payout.id}
          render={(payout) => (
            <PayoutListItem
              payout={payout}
              variant="simplified"
              onClick={() => {
                trackJobPayoutListItemClicked({
                  job_id: job.id,
                  payout_id: payout.id,
                });
                navigate('EarningsPayoutDetails', { id: payout.id });
              }}
            />
          )}
        />
      ) : null}
    </VStack>
  );
};

const BreakdownListItem = ({ label, description, total }: JobBillingBreakdown) => {
  return (
    <HStack justifyContent="space-between" py={1} flex={1}>
      <VStack>
        <Text size="m" color="text.secondary">
          {label}
        </Text>
        {!!description ? (
          <Text size="s" color="text.secondary">
            {description}
          </Text>
        ) : null}
      </VStack>
      <Text size="m" textAlign="right">
        {total}
      </Text>
    </HStack>
  );
};

const getPayoutsForSimplifiedList = ({
  job_billings,
  expense_adjustments,
}: CompletedShiftPaymentInfoProps['job']) => {
  const dupePayouts = [...job_billings, ...expense_adjustments].reduce((acc, payable) => {
    if (!!payable.payout) {
      acc.push({
        ...payable.payout,
        // stub out job_billings and expense_adjustments for simplified list item
        job_billings: [],
        expense_adjustments: [],
      });
    }
    return acc;
  }, [] as IPayoutForPayoutListItem[]);

  return uniqBy(dupePayouts, 'id');
};

const formatJobBillings = ({ job_billings, status }: CompletedShiftPaymentInfoProps['job']) => {
  const isJobSplit = job_billings.some((jb) => jb.category === 'disputable');
  return job_billings.map((job_billings) => ({
    ...job_billings,
    job: {
      status,
      isJobSplit,
    },
  }));
};

const getJobWithFilteredAdjustments = (job: CompletedShiftPaymentInfoProps['job']) => {
  const { status, expense_adjustments, job_billings } = job;
  const showPendingAdjustments = !UNPAID_ADJUSTMENT_JOB_STATUSES.includes(status);
  const filteredAdjustments = expense_adjustments.filter((adj) => {
    if (adj.payout) {
      // if an adjustment's already been paid, we can show it.
      return true;
    }
    return showPendingAdjustments;
  });

  let filteredJobBillings = job_billings;
  if (!['disputed', 'held_for_dispute_review'].includes(status)) {
    // When a job is in a final state, we only want to show standard job billings.
    filteredJobBillings = job_billings.filter((jb) => jb.category === 'standard');
  }

  return {
    ...job,
    expense_adjustments: filteredAdjustments,
    job_billings: filteredJobBillings,
  };
};
