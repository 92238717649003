import { graphql } from '@mp/graphql/generated';

export const CancellationReasonsGql = graphql(`
  query GetCancellationReasonsForCancelationPolicy($input: CancellationReasonQueryInput) {
    cancellationReasons(input: $input) {
      id
      label
    }
  }
`);

export const ProfileStatsGql = graphql(`
  query GetProfileStatsForCancelationPolicy {
    profileStats {
      no_shows_count
      late_cancellations_count
    }
  }
`);
