import React from 'react';
import { Typography } from '@medely/web-components';
import { mobilePageHeaders } from 'routes';
import { useLocation } from 'react-router';
import { usePageTitle } from '@mp';

const TopBarMobileTitle = (): React.ReactElement => {
  const { pathname } = useLocation();
  const { pageTitle } = usePageTitle();
  const route = mobilePageHeaders.find((r) => r.regExp.test(pathname));
  return <Typography fontSize="20px">{pageTitle || route?.header}</Typography>;
};

export default TopBarMobileTitle;
