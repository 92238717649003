import React, { useEffect } from 'react';
import { Box, Form, Heading, useAnalytics } from '@medely/ui-kit';
import { FixedFooterLayout } from '../../../../components/FixedFooterLayout/FixedFooterLayout';
import { WorkExperienceForm } from './WorkExperienceForm';
import { useApplicationMutations, useCurrentUser } from '../../../../hooks';
import useWorkExperienceInfoMutations from '../../../../hooks/useWorkExperienceInfoMutations';
import { ApplicationButtons } from '../../components/ApplicationButtons';
import { LoadingState } from '../../components/LoadingState';
import { createMostRecentWorkExperienceValidation } from './workExperienceSchema';
import { useWorkExperience } from './useWorkExperience';
import { useGetWorkExperienceInitialData } from './useGetWorkExperienceInitialData';
import { SetPageProps, ValuesProps } from './types';
import { FragmentType, graphql, parseFragmentData } from '../../../../graphql/generated';

const WorkExperiencePrimaryFormCurrentUserGql = graphql(`
  fragment WorkExperiencePrimaryFormCurrentUser on Account {
    id
    ...WorkExperienceFormCurrentUser
    ...UseWorkExperienceCurrentUser
  }
`);

type WorkExperiencePrimaryFormProps = {
  setPage: (value: SetPageProps) => void;
  currentUser?: FragmentType<typeof WorkExperiencePrimaryFormCurrentUserGql>;
  hasOnlyOnePosition: boolean;
};

export const WorkExperiencePrimaryForm = ({
  setPage,
  currentUser: inputCurrentUser,
  hasOnlyOnePosition,
}: WorkExperiencePrimaryFormProps) => {
  const { proType } = useCurrentUser();
  const currentUser = parseFragmentData(WorkExperiencePrimaryFormCurrentUserGql, inputCurrentUser);

  const analytics = useAnalytics();
  const { locationTypesLoading } = useGetWorkExperienceInitialData();
  const { totalYearsOfExperience, positionsOptions, createPayload, isMissingYoE } =
    useWorkExperience({ currentUser: currentUser || undefined });
  const { submitWorkExperienceInfo } = useWorkExperienceInfoMutations();
  const { prevStep, isLoading: mutationsLoading } = useApplicationMutations();

  const baseValuesLoading = locationTypesLoading;

  const disableButtons = baseValuesLoading || mutationsLoading;

  const defaultValues = hasOnlyOnePosition ? { position_id: positionsOptions[0].value } : {};

  const handleSubmit = (values: ValuesProps) => {
    const parsedValues = createPayload(values);
    submitWorkExperienceInfo(parsedValues, {
      onSuccess: () => {
        if (
          isMissingYoE({
            startDate: values.start_date.toDateString(),
            endDate: !!values.end_date ? values.end_date.toDateString() : undefined,
            positionId: values.position_id,
          })
        ) {
          setPage('addMoreMonths');
        } else {
          setPage('experienceSummary');
        }
      },
    });
  };

  useEffect(() => {
    analytics.track('Product Application Primary Experience - Viewed', {
      account_id: currentUser?.id,
      context: 'Product Application Primary Experience',
      subcontext: null,
      action: 'Viewed',
      pro_type: proType,
    });
  }, []);

  if (baseValuesLoading) {
    return <LoadingState />;
  }

  return (
    <>
      <Form
        handleSubmit={handleSubmit}
        schema={createMostRecentWorkExperienceValidation(totalYearsOfExperience)}
        defaultValues={defaultValues}
      >
        <FixedFooterLayout
          footerContent={<ApplicationButtons disabled={disableButtons} backClick={prevStep} />}
        >
          <Box pb={2}>
            <Heading size="s" color="text.primary" testId="work-experience-primary-form-title">
              Add detail for your most recent paid clinical experience in the U.S.
            </Heading>
          </Box>
          <WorkExperienceForm currentUser={currentUser} setPage={setPage} showWageLog={true} />
        </FixedFooterLayout>
      </Form>
    </>
  );
};
