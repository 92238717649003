import React from 'react';
import { Box, Heading, Text, CircularProgress } from '@medely/ui-kit';
import OnboardingList from 'components/onboarding/OnboardingList';
import useOnboardingSteps from 'hooks/useOnboardingSteps';

const Onboarding = () => {
  const { steps, isLoading } = useOnboardingSteps({ refetch: true });

  return (
    <>
      <Heading size="m" testId="onboarding-title">
        Let’s get you set up
      </Heading>
      <Box mt={0.5}>
        <Text size="m" testId="onboarding-subtitle">
          You’re almost there! Complete all onboarding steps so we can review your profile and get
          you ready to book shifts.
        </Text>
      </Box>
      <Box my={2}>
        {isLoading ? <CircularProgress size={20} /> : <OnboardingList steps={steps} />}
      </Box>
    </>
  );
};

export default Onboarding;
