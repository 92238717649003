import { yup } from '@medely/ui-kit';

export const cancellationFormSchema = (otherReasonCancellationId: number) => {
  const schema = yup.object({
    cancellation_reason_id: yup.number().required('Required'),
    other_reason: yup.string().when('cancellation_reason_id', {
      is: otherReasonCancellationId,
      then: (schema) => schema.required('Required'),
    }),
  });

  return schema;
};
