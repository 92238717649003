import React, { useState } from 'react';
import { Box, Button, Dialog, DialogProps } from '@medely/web-components';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import MissingConditionFormOptions from 'components/credentials/conditionDialogs/MissingConditionFormOptions';
import MissingConditionForm from 'components/credentials/conditionDialogs/MissingConditionForm';
import type { ICondition } from '@medely/web-components/types';
import useMissingConditionOptions, {
  type MissingConditionOption,
} from 'components/credentials/conditionDialogs/useMissingConditionOptions';
import CredentialModal from 'components/credentials/professionalQualificationModal/CredentialModal';

type CredentialUploadDialogProps = DialogProps & {
  condition: ICondition;
};

const uploadedLastRemainingStep = (
  beforeOptions: MissingConditionOption[],
  afterOptions: MissingConditionOption[],
) => {
  const optionsFinishedBefore = beforeOptions.filter(
    (option) => option.remainingActionableQualifications.length === 0,
  );
  const optionsFinishedAfter = afterOptions.filter(
    (option) => option.remainingActionableQualifications.length === 0,
  );
  return optionsFinishedAfter.length > optionsFinishedBefore.length;
};

const MissingConditionFormDialog = ({ open, onClose, condition }: CredentialUploadDialogProps) => {
  const { options: missingOptions, refetchConditionOptions } =
    useMissingConditionOptions(condition);
  const isSimpleCondition =
    missingOptions.length === 1 && missingOptions[0].qualifications.length === 1;
  const [selectedOption, setSelectedOption] = useState<MissingConditionOption | undefined>(
    undefined,
  );
  const [selectedProfessionalQualificationId, setSelectedProfessionalQualificationId] = useState<
    number | undefined
  >(undefined);

  const handleCloseOption = () => {
    setSelectedProfessionalQualificationId(undefined);
    if (isSimpleCondition || selectedOption === undefined) {
      onClose?.();
    }
    setSelectedOption(undefined);
  };

  const handleSubmitPq = async () => {
    const refetchOptions = await refetchConditionOptions();
    if (uploadedLastRemainingStep(missingOptions, refetchOptions)) {
      onClose?.();
    } else {
      handleCloseOption();
    }
  };

  if (!selectedProfessionalQualificationId) {
    return (
      <MissingConditionForm
        condition={condition}
        isSimpleCondition={isSimpleCondition}
        missingOptions={missingOptions}
        onQualificationsResolve={handleSubmitPq}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        setSelectedProfessionalQualificationId={setSelectedProfessionalQualificationId}
      >
        {({ condition, qualificationAutomationId, isActionable, submit }) => (
          <Form
            onSubmit={submit}
            mutators={{ ...arrayMutators }}
            initialValues={{ condition, qualification_automation_id: qualificationAutomationId }}
            keepDirtyOnReinitialize
          >
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Dialog
                  maxWidth="sm"
                  fullWidth
                  open={open}
                  onClose={handleCloseOption}
                  title={isActionable ? 'Upload Missing Credential' : undefined}
                  data-testid="missing-condition-form-dialog"
                  content={<MissingConditionFormOptions />}
                  actions={
                    <Box display="flex" mt={2}>
                      {isActionable && (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          disabled={submitting}
                          loading={submitting}
                          sx={{ ml: 2 }}
                          data-testid="missing-condition-form-dialog-next-button"
                        >
                          Next
                        </Button>
                      )}
                    </Box>
                  }
                />
              </form>
            )}
          </Form>
        )}
      </MissingConditionForm>
    );
  } else {
    return (
      <CredentialModal
        onClose={handleCloseOption}
        onSubmitPq={handleSubmitPq}
        professionalQualificationId={selectedProfessionalQualificationId}
        open={!!selectedProfessionalQualificationId}
      />
    );
  }
};

export default MissingConditionFormDialog;
