import { IPosition } from '@medely/types';

type JobPositionsProps = Partial<IPosition> & {
  high_risk?: boolean;
};

type JobSpecialtiesProps = {
  id?: number;
  label?: string;
};

type HasMatchingSpecialtiesForHighRiskJobProps = {
  jobSpecialties: JobSpecialtiesProps[];
  jobPositions: JobPositionsProps[];
  userSpecialties?: JobSpecialtiesProps[];
};

export const hasMatchingSpecialtiesForHighRiskJob = ({
  userSpecialties,
  jobPositions,
  jobSpecialties,
}: HasMatchingSpecialtiesForHighRiskJobProps) => {
  const hasNoHighRiskPosition = jobPositions?.some((position) => !position.high_risk);
  if (hasNoHighRiskPosition) {
    return true;
  }
  const inputUserSpecialties = userSpecialties || [];
  const inputJobSpecialties = jobSpecialties || [];
  const needsMoreSpecialties = inputJobSpecialties.every((jobSpec) =>
    inputUserSpecialties.some((userSpec) => userSpec.id === jobSpec.id),
  );

  return needsMoreSpecialties;
};

type GetMissingSpecialtiesIdsProps = {
  jobSpecialties?: JobSpecialtiesProps[] | null;
  userSpecialties?: JobSpecialtiesProps[];
};

export const getMissingSpecialtiesOptions = ({
  jobSpecialties,
  userSpecialties,
}: GetMissingSpecialtiesIdsProps) => {
  if (!jobSpecialties) {
    return [];
  }
  const jobSpecialtiesIds = jobSpecialties?.map(({ id }) => id) || [];
  const userSpecialtiesIds = userSpecialties?.map(({ id }) => id) || [];
  const jobSpecialtiesIdsNeededData = jobSpecialtiesIds?.filter(
    (s) => !userSpecialtiesIds.includes(s),
  );

  const specialtiesMissing = jobSpecialties?.filter(({ id }) =>
    jobSpecialtiesIdsNeededData.includes(id),
  );

  const options = specialtiesMissing?.map((specialty) => {
    return {
      value: specialty?.id as number,
      label: specialty?.label as string,
    };
  });

  return options;
};
