import { Box, Label, Text } from '@medely/ui-kit';
import { toPhoneNumber } from '@mp/utils/toPhoneNumber';
import React from 'react';

type CallFacilityDialogInfoProps = {
  phone?: string;
};

export const CallFacilityDialogInfo = ({ phone }: CallFacilityDialogInfoProps) => {
  return (
    <>
      <Box mb={2}>
        <Text size="m" color="text.primary">
          Use the number below or tap &quot;Call&quot; to contact the facility.
        </Text>
      </Box>
      <Label size="m" color="text.primary">
        {phone && toPhoneNumber(phone)}
      </Label>
    </>
  );
};
