import React from 'react';
import {
  Box,
  Form,
  MultiSelectField,
  OptionType,
  SubmitButton,
  Text,
  useAnalytics,
  VStack,
} from '@medely/ui-kit';
import useSpecialtiesMutations from '@mp/hooks/useSpecialtiesMutations';
import { recommendedSpecialtiesFormSchema } from './recommendedSpecialtiesFormSchema';
import { useCurrentUser, useSpecialtyTracking } from '@mp/hooks';

type RecommendedSpecialtiesInfoProps = {
  options: OptionType[];
  handleContinueClick: () => void;
  onClose: () => void;
};

type ValuesType = {
  add_specialty_ids: number[];
};

export const RecommendedSpecialtiesInfo = ({
  options,
  handleContinueClick,
  onClose,
}: RecommendedSpecialtiesInfoProps) => {
  const analytics = useAnalytics();
  const { currentUser } = useCurrentUser();
  const { trackSpecialtyRequirementsModalInteract } = useSpecialtyTracking(analytics);
  const { submitSpecialtiesInfo, isSpecialtiesLoading } = useSpecialtiesMutations();

  const defaultValues = {
    add_specialty_ids: [] as number[],
  };

  const professionalId = currentUser?.professional?.id;

  const handleSubmit = (values: ValuesType) => {
    const specialtiesToAddIds = values.add_specialty_ids;
    trackSpecialtyRequirementsModalInteract(professionalId, specialtiesToAddIds);
    submitSpecialtiesInfo(values, {
      onSuccess: () => {
        handleContinueClick();
        onClose();
      },
    });
  };
  return (
    <Box testId="recommended-specialties-info">
      <Text size="m" color="text.primary" testId="recommended-specialties-info-text">
        This shift requires skills that aren’t on your profile. Select any relevant ones to add now.
        You must have experience in these areas to work this shift.
      </Text>
      <Form
        handleSubmit={handleSubmit}
        schema={recommendedSpecialtiesFormSchema}
        defaultValues={defaultValues}
      >
        <VStack gap={2} my={2}>
          {!!options && (
            <MultiSelectField
              variant="list"
              options={options}
              label=""
              name="add_specialty_ids"
              testId="recommended-specialties-info-select"
            />
          )}
        </VStack>
        <SubmitButton
          fullWidth
          loading={isSpecialtiesLoading}
          disabled={isSpecialtiesLoading}
          testId="recommended-specialties-info-confirm-button"
        >
          Confirm and continue
        </SubmitButton>
      </Form>
    </Box>
  );
};
