import React from 'react';
import { TaskCard } from '@medely/ui-kit';
import { TaskCardLayout } from './TaskCard';
import { useProNavigate } from '../../contexts';

export type ProPoolOnboardingProps = {
  locationId: number;
  facilityName: string;
};

export const ProPoolOnboarding = (props: ProPoolOnboardingProps) => {
  const { locationId, facilityName } = props;
  const { navigate } = useProNavigate();
  const goToPoolInvite = () => navigate('LocationOnboarding', { location_id: locationId });

  const title = `Please complete your onboarding for ${facilityName}`;

  return (
    <TaskCardLayout>
      <TaskCard
        iconProps={{ name: 'exclamation-triangle', color: 'error' }}
        title={title}
        body={null}
        onClick={goToPoolInvite}
      />
    </TaskCardLayout>
  );
};
