import { ICondition } from '@medely/types';
import useGraphQLRequest from './useGraphQLRequest';
import { GET_ONBOARDING_CONDITIONS } from '@mp';
import useCurrentUser from './useCurrentUser';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { DEFAULT_REFETCH_INTERVAL } from 'constants/graphql';

export type UseMissingOnboardingConditionsArgs = {
  is_w2?: boolean;
  refetch?: boolean;
};

type Return = {
  conditions: ICondition[];
  isLoading: boolean;
};

const useMissingOnboardingConditions = ({
  is_w2,
  refetch,
}: UseMissingOnboardingConditionsArgs = {}): Return => {
  const { request } = useGraphQLRequest();
  const { currentUser } = useCurrentUser();

  const enabled = !!currentUser;

  const fetchOnboardingRequirements = async ({
    queryKey: [, _professional_id, _is_w2],
  }: QueryFunctionContext) => {
    const { conditions } = await request(GET_ONBOARDING_CONDITIONS, {
      input: {
        search: {
          for_w2_on_boarding_only: is_w2,
          missing_conditions: {
            for_on_boarding: true,
            for_application: false,
            is_w2: _is_w2,
            professional_id: _professional_id,
          },
        },
      },
    });
    return conditions;
  };

  const { data, isLoading, isInitialLoading } = useQuery(
    ['onboardingConditions', currentUser?.professional.id, is_w2],
    fetchOnboardingRequirements,
    {
      enabled,
      refetchInterval: refetch ? DEFAULT_REFETCH_INTERVAL : undefined,
    },
  );

  return { conditions: data ?? [], isLoading: enabled ? isLoading : isInitialLoading };
};

export default useMissingOnboardingConditions;
