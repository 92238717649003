import { BottomSheetDialog } from '@medely/web-components';
import React from 'react';
import { CancelationContent } from './CancelationContent';
import useDevice from '@mp/hooks/useDevice.web';
import { CancelShiftDialogProps } from '../interface';

export const CancelShiftDialog = ({
  open,
  onClose,
  title,
  job,
  utmParams,
}: CancelShiftDialogProps) => {
  const isDesktop = useDevice() === 'desktop';

  return (
    <BottomSheetDialog
      sx={{ alignSelf: isDesktop ? 'center' : 'end' }}
      open={open}
      onClose={onClose}
      title={title}
      content={<CancelationContent job={job} closeDialog={onClose} utmParams={utmParams} />}
      isDesktop={isDesktop}
      data-testid="cancel-shift-dialog"
    />
  );
};
