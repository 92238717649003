import { IJob } from '@medely/types';
import { Button } from '@medely/ui-kit';
import useDialog from '@mp/hooks/useDialog';
import moment from 'moment';
import React from 'react';
import { useCurrentUser } from '@mp/hooks';
import { calculateLateCancellation } from './cancelationUtils';
import { UtmParamsType } from './interface';
import { CancelShiftDialog } from './cancellationDialog/CancelShiftDialog';

type CancelShiftButtonProps = {
  job: IJob;
  utmParams?: UtmParamsType;
};

export const CancelShiftButton = ({ job, utmParams }: CancelShiftButtonProps) => {
  const { isSaas } = useCurrentUser();
  const { open, openDialog, closeDialog } = useDialog();
  const { showLateCancellationWarning } = calculateLateCancellation({
    bookedAt: job.booked_at,
    currentStartsTime: job.current_starts_time,
    jobAssignment: job.assignment,
    updatedInCancellationWindow: job.updated_in_cancellation_window,
  });
  const showButton = !(
    job.status === 'clocked_in' || moment(job.current_starts_time).isBefore(moment())
  );
  const dialogTitle =
    showLateCancellationWarning && !isSaas ? 'Confirm Late Cancellation' : 'Confirm Cancellation';

  return (
    <>
      {showButton && (
        <Button
          variant="outlined"
          onClick={() => openDialog()}
          testId="cancel-shift-button"
          fullWidth
        >
          Cancel shift
        </Button>
      )}
      <CancelShiftDialog
        open={open}
        onClose={closeDialog}
        title={dialogTitle}
        job={job}
        utmParams={utmParams}
      />
    </>
  );
};
