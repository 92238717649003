/* eslint-disable medely/noUseCallbackUseMemo */
import { useCallback } from 'react';
import debounce from 'lodash/debounce';

import { IExpenseAdjustment, IJob, IPayout } from '@medely/types';
import { useAnalytics } from '@medely/ui-kit';
import { useCurrentUser } from '../useCurrentUser';

enum CONTEXTS {
  EARNINGS = 'Earnings',
  PAYOUT_DETAILS = 'Payout Details',
  ADJUSTMENT_DETAILS = 'Expense Adjustment Details',
  JOB_DETAILS = 'Job Details',
  EARNINGS_PENDING = 'Earnings Pending',
  JOB_LIST_ITEM = 'Job List Item',
  ADJUSTMENT_LIST_ITEM = 'Expense Adjustment List Item',
  PAYOUT_LIST_ITEM = 'Payout List Item',
  LIST_ITEM = 'List Item',
}

enum ACTIONS {
  VIEWED = 'Viewed',
  CLICKED = 'Clicked',
}

const DEFAULT_DEBOUNCE_TIME = 500;

type TrackEarningsViewPayload = {
  month: string;
};
type TrackPayoutDetailsViewPayload = {
  payout_id: IPayout['id'];
};
type TrackAdjustmentDetailsViewPayload = {
  expense_adjustment_id: IExpenseAdjustment['id'];
};
type TrackPendingListItemClickedPayload = {
  job_id?: IJob['id'];
  expense_adjustment_id?: IExpenseAdjustment['id'];
};
type TrackJobListItemClickedPayload = {
  job_id: IJob['id'];
  payout_id: IPayout['id'];
};
type TrackAdjustmentListItemClickedPayload = {
  expense_adjustment_id: IExpenseAdjustment['id'];
  payout_id: IPayout['id'];
};
type TrackPayoutListItemClickedPayload = {
  job_id?: IJob['id'];
  expense_adjustment_id?: IExpenseAdjustment['id'];
  payout_id: IPayout['id'];
};

interface IUseEarningsEvent {
  trackEarningsView: (payload: TrackEarningsViewPayload) => void;
  trackPayoutDetailsView: (payload: TrackPayoutDetailsViewPayload) => void;
  trackAdjustmentDetailsView: (payload: TrackAdjustmentDetailsViewPayload) => void;
  trackEarningsPendingView: () => void;
  trackPendingListItemClicked: (payload: TrackPendingListItemClickedPayload) => void;
  trackPayoutJobListItemClicked: (payload: TrackJobListItemClickedPayload) => void;
  trackPayoutAdjustmentListItemClicked: (payload: TrackAdjustmentListItemClickedPayload) => void;
  trackEarningsPayoutListItemClicked: (payload: TrackPayoutListItemClickedPayload) => void;
  trackJobPayoutListItemClicked: (payload: TrackPayoutListItemClickedPayload) => void;
  trackAdjustmentDetailsPayoutListItemClicked: (payload: TrackPayoutListItemClickedPayload) => void;
}

export const useEarningsEvent = (): IUseEarningsEvent => {
  const analytics = useAnalytics();
  const { proType } = useCurrentUser();

  const trackEarningsView = useCallback(
    debounce((payload: TrackEarningsViewPayload) => {
      analytics.track({
        context: CONTEXTS.EARNINGS,
        subcontext: null,
        action: ACTIONS.VIEWED,
        pro_type: proType,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackPayoutDetailsView = useCallback(
    debounce((payload: TrackPayoutDetailsViewPayload) => {
      analytics.track({
        context: CONTEXTS.PAYOUT_DETAILS,
        subcontext: null,
        action: ACTIONS.VIEWED,
        pro_type: proType,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackAdjustmentDetailsView = useCallback(
    debounce((payload: TrackAdjustmentDetailsViewPayload) => {
      analytics.track({
        context: CONTEXTS.ADJUSTMENT_DETAILS,
        subcontext: null,
        action: ACTIONS.VIEWED,
        pro_type: proType,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackEarningsPendingView = useCallback(
    debounce(() => {
      analytics.track({
        context: CONTEXTS.EARNINGS_PENDING,
        subcontext: null,
        action: ACTIONS.VIEWED,
        pro_type: proType,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackPendingListItemClicked = useCallback(
    debounce((payload: TrackPendingListItemClickedPayload) => {
      analytics.track({
        context: CONTEXTS.EARNINGS_PENDING,
        subcontext: CONTEXTS.LIST_ITEM,
        action: ACTIONS.CLICKED,
        pro_type: proType,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackPayoutJobListItemClicked = useCallback(
    debounce((payload: TrackJobListItemClickedPayload) => {
      analytics.track({
        context: CONTEXTS.PAYOUT_DETAILS,
        subcontext: CONTEXTS.JOB_LIST_ITEM,
        action: ACTIONS.CLICKED,
        pro_type: proType,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackPayoutAdjustmentListItemClicked = useCallback(
    debounce((payload: TrackAdjustmentListItemClickedPayload) => {
      analytics.track({
        context: CONTEXTS.PAYOUT_DETAILS,
        subcontext: CONTEXTS.ADJUSTMENT_LIST_ITEM,
        action: ACTIONS.CLICKED,
        pro_type: proType,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackEarningsPayoutListItemClicked = useCallback(
    debounce((payload: TrackPayoutListItemClickedPayload) => {
      analytics.track({
        context: CONTEXTS.EARNINGS,
        subcontext: CONTEXTS.PAYOUT_LIST_ITEM,
        action: ACTIONS.CLICKED,
        pro_type: proType,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackJobPayoutListItemClicked = useCallback(
    debounce((payload: TrackPayoutListItemClickedPayload) => {
      analytics.track({
        context: CONTEXTS.JOB_DETAILS,
        subcontext: CONTEXTS.PAYOUT_LIST_ITEM,
        action: ACTIONS.CLICKED,
        pro_type: proType,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const trackAdjustmentDetailsPayoutListItemClicked = useCallback(
    debounce((payload: TrackPayoutListItemClickedPayload) => {
      analytics.track({
        context: CONTEXTS.ADJUSTMENT_DETAILS,
        subcontext: CONTEXTS.PAYOUT_LIST_ITEM,
        action: ACTIONS.CLICKED,
        pro_type: proType,
        ...payload,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return {
    trackEarningsView,
    trackPayoutDetailsView,
    trackAdjustmentDetailsView,
    trackEarningsPendingView,
    trackPendingListItemClicked,
    trackPayoutJobListItemClicked,
    trackPayoutAdjustmentListItemClicked,
    trackEarningsPayoutListItemClicked,
    trackJobPayoutListItemClicked,
    trackAdjustmentDetailsPayoutListItemClicked,
  };
};

export default useEarningsEvent;
