import { useFirebaseContext, CircularProgress, useConfigurationContext } from '@medely/ui-kit';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { SignedDocumentWebView } from './SignedDocument';
import { ContractWebView } from './Contract';
import TypeFormWebView from './Typeform';
import { HelpArticle } from './HelpArticle';
import { TermsOfService } from './TermsOfService';
import OnboardingWebViewEmbedded from './OnboardingWebViewEmbedded';

export const WebViews = () => {
  const config = useConfigurationContext();
  const { googleUser, signInWithToken } = useFirebaseContext();

  const [webViewToken, setWebViewToken] = useQueryParam('webViewToken', StringParam);

  useEffect(() => {
    const signIn = async (token: string) => {
      try {
        await signInWithToken(token);
      } catch (e) {
        console.error(e);
      } finally {
        // clear the token so the user sees either the correct screen or an error
        // against a blank screen instead of an infinite spinner
        setWebViewToken(undefined);
      }
    };
    // if the user is already authenticated, we have no guarantee they're signed into the expected
    // account, so we have to force a new login attempt with the webview token
    if (webViewToken) {
      signIn(webViewToken);
    }
  }, [webViewToken]);

  // don't try to show anything until the proper user is signed in
  return !!googleUser && !webViewToken ? (
    <Switch>
      <Route path={`${config.root}/wv/signed_document`} component={SignedDocumentWebView} />
      <Route path={`${config.root}/wv/contract`} component={ContractWebView} />
      <Route path={`${config.root}/wv/typeform`} component={TypeFormWebView} />
      <Route path={`${config.root}/articles-view`} component={HelpArticle} />
      <Route path={`${config.root}/wv/terms-of-service`} component={TermsOfService} />
      <Route path={`${config.root}/wv/onboarding`} component={OnboardingWebViewEmbedded} />
    </Switch>
  ) : (
    <CircularProgress />
  );
};
