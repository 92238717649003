import React from 'react';
import { Text } from '@medely/ui-kit';

export const UnsavedChangesInfo = () => {
  return (
    <Text size="m" color="text.primary" testId="unsaved-changes-info">
      Going back will discard any changes you’ve made. Are you sure you want to continue?
    </Text>
  );
};
