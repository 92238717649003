import { IQualification } from '@medely/types';

export const ADDRESS_CONDITION_SLUG = 'home_address';
export const BACKGROUND_CHECK_CONDITION_SLUG = 'background_check';
export const DIRECT_DEPOSIT_CONDITION_SLUG = 'direct_deposit';
export const EXPERIENCE_CONDITION_SLUG = 'has_work_history_or_uploaded_resume';
export const UNIVERSAL_BACKGROUND_CHECK_CONDITION_SLUG = 'universal_background_check';

export const NON_CREDENTIAL_SLUGS = [
  ADDRESS_CONDITION_SLUG,
  BACKGROUND_CHECK_CONDITION_SLUG,
  DIRECT_DEPOSIT_CONDITION_SLUG,
  UNIVERSAL_BACKGROUND_CHECK_CONDITION_SLUG,
];

export const CREDENTIAL_QUALIFICATION_TYPES: IQualification['qualification_type'][] = [
  'certification',
  'license',
  'document',
];
