import { lightTheme, ThemeProvider } from '@medely/web-components/themes';
import AppRouter from 'AppRouter';
import { ReactQueryProvider } from 'context/reactQueryContext';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './styles/global.css';
import { default as configuration } from 'config';
import { StripeProvider } from '@medely/web-components/contexts';
import { FeatureGateProvider, useFeatureGateContext } from 'context/featureGateContext';
import GraphQLPermissionsContextProvider, {
  useGraphQLPermissionsContext,
} from 'context/graphqlContext';
import { useFirebaseContext } from '@medely/ui-kit/web';
import CenteredLoader from 'components/CenteredLoader';
import { PageTitleProvider, ProNavigateProvider, WebViewProvider } from '@mp';
import { useAnalytics } from '@medely/ui-kit';

const App = () => {
  return (
    <WebViewProvider>
      <ReactQueryProvider>
        <FeatureGateProvider>
          <GraphQLPermissionsContextProvider>
            <ThemeProvider theme={lightTheme}>
              <StripeProvider stripeKey={configuration.stripePublishableKey}>
                <PageTitleProvider>
                  <ProNavigateProvider>
                    <LoadingBox>
                      <AppRouter />
                    </LoadingBox>
                  </ProNavigateProvider>
                </PageTitleProvider>
              </StripeProvider>
            </ThemeProvider>
          </GraphQLPermissionsContextProvider>
        </FeatureGateProvider>
      </ReactQueryProvider>
    </WebViewProvider>
  );
};

const LoadingBox = ({ children }: { children: ReactNode }) => {
  const { loading: isLoadingFirebase } = useFirebaseContext();
  const { isLoading: isLoadingFeatureGateContext } = useFeatureGateContext();
  const { isLoading: isLoadingGraphQLPermissions } = useGraphQLPermissionsContext();
  const [{ pathname, isWebView, isMobileRedirect }] = useState(() => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const searchParams = url.searchParams;
    const isWebView = searchParams.get('webView') === '1';
    const isMobileRedirect = isWebView || !!searchParams.get('mobileToken');

    return {
      pathname,
      isWebView,
      isMobileRedirect,
    };
  });

  const isLoading = isLoadingFirebase || isLoadingGraphQLPermissions || isLoadingFeatureGateContext;

  useEffect(() => {
    analytics.track({
      context: 'TEMP Authentication',
      subcontext: 'Initialization',
      action: 'Start',
      isWebView,
      isMobileRedirect,
      pathname,
    });
  }, []);

  const traceStart = useRef(Date.now());
  const traceFired = useRef(false);
  const analytics = useAnalytics();
  useEffect(() => {
    if (!isLoadingFirebase && !traceFired.current) {
      const traceEnd = Date.now();
      const latency = traceEnd - traceStart.current;

      analytics.track({
        context: 'TEMP Authentication',
        subcontext: 'Initialization',
        action: 'Complete',
        isWebView,
        isMobileRedirect,
        latency,
        pathname,
      });
      traceFired.current = true;
    }
  }, [isLoadingFirebase]);

  if (isLoading) {
    return <CenteredLoader />;
  }

  return <>{children}</>;
};

export default App;
