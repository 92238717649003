import React from 'react';
import { JobDetailsListItem } from '../JobDetailsListItem';
import { Box, HStack, Icon, Text } from '@medely/ui-kit';
import { useCurrentUser } from '@mp/hooks';
type ChartingReminderProps = {
  smallIcon?: boolean;
  isEndShift?: boolean;
};

const ChartingReminderIcon = ({ smallIcon }: Pick<ChartingReminderProps, 'smallIcon'>) => {
  return (
    <Icon
      name="light-clipboard-check"
      iconSize={smallIcon ? '14px' : '20px'}
      color="text.primary"
      variant="custom"
    />
  );
};

export const ChartingReminder = ({
  smallIcon = false,
  isEndShift = false,
}: ChartingReminderProps) => {
  const { isAgency, isSaas } = useCurrentUser();

  if (isAgency || isSaas) {
    return null;
  }

  if (isEndShift) {
    return (
      <HStack alignContent="center">
        <ChartingReminderIcon smallIcon={smallIcon} />
        <Box pl={2}>
          <Text size="l" color="text.secondary">
            Complete charting to avoid facility disputes.
          </Text>
        </Box>
      </HStack>
    );
  }

  return (
    <JobDetailsListItem title="Charting" icon={<ChartingReminderIcon smallIcon={smallIcon} />}>
      <Text size="m" color="text.secondary">
        Please complete all patient charts by the end of your shift to prevent payout delays.
      </Text>
    </JobDetailsListItem>
  );
};
