import { getApp, getApps, initializeApp } from 'firebase/app';
import { signInWithEmailAndPassword, initializeAuth, browserLocalPersistence } from 'firebase/auth';
import { defaultConfig } from '@medely/base';

// https://github.com/firebase/firebase-js-sdk/issues/7888#issuecomment-2114939292
const app =
  getApps().length === 0
    ? initializeApp({ ...defaultConfig.firebaseConfig, appId: 'medely-app' }, 'medely-app')
    : getApp();
// NOTE: getAuth() seems to cause a 20 second hang intermittently on embedded mobile
const auth = initializeAuth(app, { persistence: browserLocalPersistence });
export default auth;
export { auth as firebaseAuth, signInWithEmailAndPassword };
