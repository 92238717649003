import { ICondition, IProfessionalQualification } from '@medely/types';

type UseSkillsAssessmentsOnboardingStepProps = {
  completedSkillsAssessments: Partial<IProfessionalQualification>[];
  missingSkillsAssessments: Partial<ICondition>[];
};

const useSkillsAssessmentsOnboardingStep = ({
  completedSkillsAssessments,
  missingSkillsAssessments,
}: UseSkillsAssessmentsOnboardingStepProps) => {
  const completedCount = completedSkillsAssessments.reduce(
    (acct, skillAssessment) => (skillAssessment.status === 'approved' ? acct + 1 : acct),
    0,
  );

  const totalSkillsAssessments =
    completedSkillsAssessments.length + missingSkillsAssessments.length;

  const onboardingSkillsAssessmentsHint = completedCount
    ? `${completedCount} of ${totalSkillsAssessments} completed`
    : undefined;

  return {
    onboardingSkillsAssessmentsHint,
  };
};

export default useSkillsAssessmentsOnboardingStep;
