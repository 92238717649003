import { IJob, IPosition } from '@medely/types';
import { Box, Icon, Text } from '@medely/ui-kit';
import React from 'react';
import { JobDetailsListItem } from '../../JobDetailsListItem';
import { ShiftLocation } from '../../ShiftLocation';
import { ShiftDate } from '../../ShiftDate';
import { useCurrentUser } from '@mp/hooks';
import { DisplayHelpers } from '@mp/utils';
import { COVID_REQUIREMENTS } from '@mp/constants';
import { HelpCenterLink } from '@mp/components/helpCenterLink';

type PositionsProps = Partial<IPosition> & { high_risk?: boolean };

type JobProps = Omit<IJob, 'positions'> & { positions: PositionsProps[] };

type AvailableGeneralInfoProps = {
  job: JobProps;
  smallIcon?: boolean;
};

export const AvailableGeneralInfo = ({ job, smallIcon }: AvailableGeneralInfoProps) => {
  const { isSaas, isAgency, currentUser } = useCurrentUser();
  const covidRequirement =
    job.location?.covid_requirement || job.location?.state?.covid_requirement;
  const covidText =
    COVID_REQUIREMENTS.info[covidRequirement as keyof typeof COVID_REQUIREMENTS.info] ?? '';
  const bookedOrInProgressOrCompleted = ['booked', 'clocked_in', 'clocked_out'].includes(
    job.status,
  );

  const isHighRiskPosition = job.positions.some((p) => p.high_risk);

  return (
    <>
      <Box py={1.5}>
        <JobDetailsListItem
          icon={
            <Icon
              name="light-location-dot"
              iconSize={smallIcon ? '14px' : '20px'}
              color="text.primary"
              variant="custom"
            />
          }
          title="Location"
        >
          <ShiftLocation
            job={job}
            bookVariant={
              !bookedOrInProgressOrCompleted || isSaas || isAgency ? 'pre-book' : 'post-book'
            }
            distanceVariant="local"
            userCoordinates={currentUser?.coordinates}
          />
        </JobDetailsListItem>
      </Box>
      <Box py={1.5}>
        <JobDetailsListItem
          icon={
            <Icon
              name="light-calendar"
              iconSize={smallIcon ? '14px' : '20px'}
              color="text.primary"
              variant="custom"
            />
          }
          title="Date"
        >
          <ShiftDate job={job} displayStartingSoonBadge />
        </JobDetailsListItem>
      </Box>
      <Box py={1.5}>
        <JobDetailsListItem
          icon={
            <Icon
              name="light-clock"
              iconSize={smallIcon ? '14px' : '20px'}
              color="text.primary"
              variant="custom"
            />
          }
          title="Shift time"
        >
          <Text size="m">{DisplayHelpers.job.time(job)}</Text>
        </JobDetailsListItem>
      </Box>
      {job.specialties && (
        <Box py={1.5}>
          <JobDetailsListItem
            icon={
              <Icon
                name="light-star-of-life"
                iconSize={smallIcon ? '14px' : '20px'}
                color="text.primary"
                variant="custom"
              />
            }
            title="Specialties"
          >
            <Text size="m">{DisplayHelpers.job.specialties(job)}</Text>
            {isHighRiskPosition && (
              <Box
                bgColor="state.info.backing-1"
                p={0.5}
                width={smallIcon ? '30%' : '18%'}
                borderRadius="5px"
                mt={1}
              >
                <Text
                  size="s"
                  color="state.info.secondary"
                  textAlign="center"
                  testId="specialty-required-badge"
                >
                  Required
                </Text>
              </Box>
            )}
          </JobDetailsListItem>
        </Box>
      )}
      {job.surgery_types && (
        <Box py={1.5}>
          <JobDetailsListItem
            icon={
              <Icon
                name="ligh-scalpel"
                iconSize={smallIcon ? '14px' : '20px'}
                color="text.primary"
                variant="custom"
              />
            }
            title="Procedures"
          >
            <Text size="m">{DisplayHelpers.job.procedures(job)}</Text>
          </JobDetailsListItem>
        </Box>
      )}
      <Box py={1.5}>
        <JobDetailsListItem
          icon={
            <Icon
              name="light-head-side-medical"
              iconSize={smallIcon ? '14px' : '20px'}
              color="text.primary"
              variant="custom"
            />
          }
          title="Patient population"
        >
          <Text size="m">{DisplayHelpers.job.patientPopulation(job)}</Text>
        </JobDetailsListItem>
      </Box>
      {covidRequirement && (
        <Box mb={3} testId="job-highlights-covid-requirement">
          <JobDetailsListItem
            icon={
              <Icon
                name="light-syringe"
                iconSize={smallIcon ? '14px' : '20px'}
                color="text.primary"
                variant="custom"
              />
            }
            title="Covid protocol"
          >
            <>
              <Text size="m">{covidText}</Text>
              <Box testId="job-highlights-covid-requirement-link">
                <HelpCenterLink text="Learn more" configKey="covidVaccineOrTest" />
              </Box>
            </>
          </JobDetailsListItem>
        </Box>
      )}
    </>
  );
};
