import React, { useEffect, useRef, useState } from 'react';
import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';
import { useTermsOfService, useSendContractAgreement } from '@mp';
import { isDevelopment } from '@src/utils/env';
import { notifyRollbar } from '@src/utils/Rollbar/notifyRollbar';
import { Box, Button } from '@medely/web-components';
import config from '@src/config';
import useCurrentUser from '@src/hooks/useCurrentUser';
import useSnackbar from '@src/hooks/useSnackbar';

declare global {
  interface Window {
    medely?: {
      termsOfServiceSigned: (accountId: number) => void;
    };
  }
}

type ValidEvent = {
  contracts: number[];
  versions: string[];
  group: number;
};

const accessId = config.ironclad.accessId;

export const TermsOfService = () => {
  const snackbar = useSnackbar();
  const { currentUser, isAgency } = useCurrentUser();
  const [valid, setValid] = useState<ValidEvent | null>(null);
  const [checked, setChecked] = useState(false);
  const initialCheckRef = useRef(true);

  const isImpersonating = window.localStorage.getItem('impersonating') === 'true';
  const accountId = currentUser?.id ?? 0;
  const groupKey = config.ironclad.groupKeys.scroll[isAgency ? 'tosAgency' : 'tosMarketplace'];
  const signerId = String(currentUser?.email);
  const testMode = isDevelopment() || isImpersonating;

  const { hasSignedLatestTerms, isLoading, isSuccess } = useTermsOfService({
    accessId,
    signerId,
    groupKey,
  });

  const { sendAgreementAsync, isLoading: isSendingAgreement } = useSendContractAgreement({
    accessId,
    signerId,
  });

  // All contracts accepted
  const handleValid = (e: ValidEvent) => {
    setValid(e);
  };
  const handleCheck = () => setChecked(true);
  const handleUncheck = () => setChecked(false);

  const handleSubmit = async () => {
    if (!valid || !checked) {
      snackbar.warning('Please accept the terms of service');
      return;
    }

    try {
      const groupId = String(valid.group);
      const versionIds = valid.versions;
      await sendAgreementAsync({ groupId, versionIds });
      window.medely?.termsOfServiceSigned(accountId);
    } catch (e) {
      snackbar.error(null, 'Something went wrong, unable to send acceptance');
      notifyRollbar({
        errorLabel: 'Failed to send contract acceptance to ironclad',
        args: {
          acccountId: currentUser?.id,
          error: e,
        },
      });
    }
  };

  const handleError = (message: any) => {
    console.error('Failed to accept terms of service for mobile user', message);

    snackbar.error(message, 'Something went wrong');
    notifyRollbar({
      errorLabel: 'Failed to accept terms of service for mobile user',
      args: {
        accountId: currentUser?.id,
        groupKey,
      },
    });
  };

  useEffect(() => {
    if (isSuccess && hasSignedLatestTerms && initialCheckRef.current) {
      notifyRollbar({
        errorLabel: 'Displaying TOS even though user has already signed TOS',
        args: {
          accountId: currentUser?.id,
          groupKey,
        },
      });
    }

    // We only want to perform this alarm check on initial load
    if (isSuccess) {
      initialCheckRef.current = false;
    }
  }, [hasSignedLatestTerms, isSuccess]);

  if (!signerId) {
    return null;
  }

  return (
    <Box>
      <PSClickWrap
        accessId={accessId}
        groupKey={groupKey}
        signerId={signerId}
        testMode={testMode}
        displayAll={true}
        disableSending={true}
        onChecked={handleCheck}
        onUnchecked={handleUncheck}
        onValid={handleValid}
        onError={handleError}
      />
      <Box zIndex={1} display="flex" justifyContent="flex-end">
        <Button
          loading={isLoading || isSendingAgreement}
          disabled={isSendingAgreement}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default TermsOfService;
