import { ICondition, IProfessionalQualification } from '@medely/types';
import useGraphQLRequest from './useGraphQLRequest';
import { GET_SKILLS_ASSESSMENTS } from '@mp';
import useMissingConditions from './useMissingConditions';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import useCurrentUser from './useCurrentUser';
import { DEFAULT_REFETCH_INTERVAL } from 'constants/graphql';

type UseSearchSkillsAssessmentsInput = {
  refetch?: boolean;
  statuses?: string[];
};

const useSearchSkillsAssessments = ({ refetch, statuses }: UseSearchSkillsAssessmentsInput) => {
  const { currentUser } = useCurrentUser();
  const { request } = useGraphQLRequest();
  const enabled = !!currentUser;

  const fetchSkillsAssessments = async ({
    queryKey: [, _statuses],
  }: QueryFunctionContext<[unknown, string[]]>) => {
    const { professionalQualifications } = await request(GET_SKILLS_ASSESSMENTS, {
      input: {
        search: {
          statuses: _statuses,
          qualification_types: ['typeform', 'external_skills_assessment'],
          not_archived: true,
        },
      },
    });
    return professionalQualifications;
  };

  const { data: skillsAssessments, ...props } = useQuery(
    ['skillsAssessments', statuses],
    fetchSkillsAssessments,
    {
      enabled,
      refetchInterval: refetch ? DEFAULT_REFETCH_INTERVAL : undefined,
    },
  );

  return { ...props, skillsAssessments: skillsAssessments ?? [] };
};

type UseSkillsAssessmentsResult = {
  completedSkillsAssessments: IProfessionalQualification[];
  missingSkillsAssessments: ICondition[];
  isLoading: boolean;
};

const useSkillsAssessments = (
  input: UseSearchSkillsAssessmentsInput = {},
): UseSkillsAssessmentsResult => {
  const { conditions } = useMissingConditions({ refetch: input.refetch });
  const { isLoading, skillsAssessments } = useSearchSkillsAssessments(input);

  const approvedSkillsQualificationIds = skillsAssessments
    ?.filter((skill) => skill?.status === 'approved')
    ?.map((skill) => skill?.qualification?.id);

  const pendingStatusSkillsAssessments = skillsAssessments?.filter((sa) => sa.status === 'pending');

  return {
    completedSkillsAssessments: skillsAssessments,
    missingSkillsAssessments: conditions?.filter((c) => {
      const qualification = c?.qualifications?.[0];
      const isPendingStatus: boolean | undefined = pendingStatusSkillsAssessments?.some(
        (sa) => sa.qualification?.id === qualification?.id,
      );

      return (
        qualification?.qualification_type === 'typeform' &&
        !approvedSkillsQualificationIds?.includes(qualification?.id) &&
        !isPendingStatus
      );
    }),
    isLoading,
  };
};

export default useSkillsAssessments;
