import { useNetworkRequest } from '../../../hooks/useNetworkRequest';
import { useProNavigate } from '../../../index';
import { useMutation } from '@tanstack/react-query';
import { useToast } from '@medely/ui-kit';

const useProPoolInviteMutations = (facilityName: string) => {
  const { axios } = useNetworkRequest();
  const { navigate } = useProNavigate();
  const toast = useToast();
  const goToPoolInviteAccepted = () => navigate('ProPoolInviteAccepted', { facilityName });

  const { mutate: acceptPoolInvite, isLoading: isSubmittingAccept } = useMutation(
    (id: number) => {
      return axios.patch(`/v3/professional/professional_pool_invites/${id}`, {
        professional_qualification: { accepted: true },
      });
    },
    {
      onError: () => {
        toast.error('Error accepting pool invite, please try again');
      },
      onSuccess: () => {
        goToPoolInviteAccepted();
      },
    },
  );

  const { mutate: rejectPoolInvite, isLoading: isSubmittingReject } = useMutation(
    (id: number) => {
      return axios.patch(`/v3/professional/professional_pool_invites/${id}`, {
        professional_qualification: { accepted: false },
      });
    },
    {
      onError: () => {
        toast.error('Error rejecting pool invite, please try again');
      },
      onSuccess: () => {
        navigate('Today');
      },
    },
  );

  return {
    acceptPoolInvite,
    rejectPoolInvite,
    isSubmittingAccept,
    isSubmittingReject,
  };
};

export default useProPoolInviteMutations;
