import { graphql } from '../graphql/generated';
import { useGraphQLQuery } from './useGraphQLRequest';
import { type ResultOf } from '@graphql-typed-document-node/core';

const ProfessionalCohortGql = graphql(`
  query GetProfessionalCohort($jobId: Int!) {
    me {
      professional {
        cohort_reminder(job_id: $jobId)
      }
    }
  }
`);

type ProfessionalCohort = ResultOf<typeof ProfessionalCohortGql>;

export type UseProfessionalCohortOptions = {
  professionalId?: number;
  jobId: number;
};

export type UseProfessionalCohortResult = {
  isLoading: boolean;
  professionalCohort: string | null | undefined;
};

export const useProfessionalCohort = (
  options: UseProfessionalCohortOptions,
): UseProfessionalCohortResult => {
  const { professionalId, jobId } = options;
  const enabled = !!professionalId;

  const { data, isLoading, isInitialLoading } = useGraphQLQuery<ProfessionalCohort>({
    operationName: 'GetProfessionalCohort',
    query: ProfessionalCohortGql,
    enabled,
    variables: {
      professionalId,
      jobId,
    },
  });

  return {
    isLoading: enabled ? isLoading : isInitialLoading,
    professionalCohort: data?.me && data?.me?.professional?.cohort_reminder,
  };
};
