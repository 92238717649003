import axios, { AxiosRequestConfig } from 'axios';
import { sessionTools } from '@medely/base';
import { firebaseAuth } from '@mp';

const getToken = async (config: AxiosRequestConfig) => {
  const [token, sessionId] = await Promise.all([
    firebaseAuth.currentUser?.getIdToken(),
    sessionTools.getOrUpdateSessionId(),
  ]);

  config.headers = {
    Authorization: `Bearer ${token}`,
    'X-Session-Id': sessionId,
  };

  return config;
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || process.env.EXPO_PUBLIC_API_URL,
});
axiosInstance.interceptors.request.use(getToken, (error) => {
  return Promise.reject(error);
});

export default axiosInstance;
export { AxiosResponse, AxiosRequestConfig } from 'axios';
