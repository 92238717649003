import { TermsOfServiceModal, TermsOfServiceModalProps } from '@medely/web-components';
import useCurrentUser from 'hooks/useCurrentUser';
import React from 'react';
import { useGate } from 'statsig-react';
import useTermsOfServiceAnalytics from './useTermsOfServiceAnalytics';
import config from 'config';
import { isDevelopment } from '@src/utils/env';
import { gateNames } from '@mp';

type ProfessionalTermsOfServiceModalProps = Pick<TermsOfServiceModalProps, 'onError' | 'onSuccess'>;

const ProfessionalTermsOfServiceModal = ({
  onError,
  onSuccess,
}: ProfessionalTermsOfServiceModalProps) => {
  const { isApplicant, currentUser, isAgency, isSaas } = useCurrentUser();
  const { trackViewed, trackCompleted } = useTermsOfServiceAnalytics('Modal');

  const { value: tosOn } = useGate(gateNames.termsOfService);
  const isImpersonating = window.localStorage.getItem('impersonating') === 'true';
  const signerId = String(currentUser?.email);
  const testMode = isDevelopment() || isImpersonating;

  const showTosModal = currentUser && tosOn && !isImpersonating && !isSaas;

  const tosGroupKey = isAgency
    ? config.ironclad.groupKeys.scroll.tosAgency
    : config.ironclad.groupKeys.scroll.tosMarketplace;

  return (
    <>
      {showTosModal && (
        <TermsOfServiceModal
          disabled={isApplicant && !isAgency}
          accessId={config.ironclad.accessId}
          groupKey={tosGroupKey}
          signerId={signerId}
          testMode={testMode}
          onDisplayed={trackViewed}
          onError={onError}
          onSuccess={() => {
            trackCompleted();
            onSuccess?.call(this);
          }}
        />
      )}
    </>
  );
};

export default ProfessionalTermsOfServiceModal;
