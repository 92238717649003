import { ICondition, IProfessionalQualification } from '@medely/types';
import { ConditionSatisfactionHelper } from '@medely/credentials-tools';
import { CREDENTIAL_QUALIFICATION_TYPES, NON_CREDENTIAL_SLUGS } from './constants';

export const isCredentialCondition = (condition: ICondition) =>
  condition.qualifications.some(
    (qualification) =>
      CREDENTIAL_QUALIFICATION_TYPES.includes(qualification.qualification_type) &&
      !NON_CREDENTIAL_SLUGS.includes(condition.slug),
  );

export const evaluateBgCheckStep = ({
  allMissingOnboardingConditions,
  bgCheckProfessionalQualifications,
}: {
  allMissingOnboardingConditions: ICondition[];
  bgCheckProfessionalQualifications: IProfessionalQualification[];
}) => {
  const missingBgCheckCondition = allMissingOnboardingConditions?.find(
    (condition) => condition.slug === 'background_check',
  );
  const bgCheckAwaitingReview = (() => {
    if (!missingBgCheckCondition) {
      return false;
    }
    const conditionHelper = new ConditionSatisfactionHelper({
      condition: missingBgCheckCondition,
      professionalQualifications: bgCheckProfessionalQualifications,
    });
    // we need to keep incomplete here instead of the isAwaitingReview method
    // because background check PQ's are created in the incomplete status
    // while awaiting data from Checkr, unlike normal PQ's a background check
    // pq status in incomplete means a professional has done everything they need to do
    return conditionHelper.unsatisfiedDueToStatuses(['pending', 'review', 'incomplete']);
  })();

  const bgCheckIsComplete = !missingBgCheckCondition || bgCheckAwaitingReview;
  return { bgCheckIsComplete, bgCheckAwaitingReview };
};

export const evaluateCredentialsStep = ({
  allMissingOnboardingConditions,
  allProfessionalQualifications,
}: {
  allMissingOnboardingConditions: ICondition[];
  allProfessionalQualifications: IProfessionalQualification[];
}) => {
  const credentialConditions = allMissingOnboardingConditions.filter(isCredentialCondition);
  const credentialsCompleted = [];
  let onboardingConditionsAreAwaitingReview = Boolean(credentialConditions.length);

  credentialConditions.forEach((condition) => {
    const conditionHelper = new ConditionSatisfactionHelper({
      condition,
      professionalQualifications: allProfessionalQualifications,
    });

    if (!conditionHelper.isAwaitingReview) {
      onboardingConditionsAreAwaitingReview = false;
    }

    if (conditionHelper.isSatisfied || conditionHelper.isAwaitingReview) {
      credentialsCompleted.push(condition);
    }
  });

  const onboardingConditionsAreComplete =
    !allMissingOnboardingConditions.length ||
    !credentialConditions.length ||
    onboardingConditionsAreAwaitingReview;

  const onboardingConditionsHint = credentialsCompleted.length
    ? `${credentialsCompleted.length} of ${credentialConditions.length} completed`
    : undefined;

  return {
    onboardingConditionsAreComplete,
    onboardingConditionsAreAwaitingReview,
    onboardingConditionsHint,
  };
};
