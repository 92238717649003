import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import { IExpenseAdjustment, IJob, IJobBilling, IJobBillingSums, IPayout } from '@medely/types';
import { getBaseOTLabel, PayoutJobBillingHelper, type IPayoutBillingHelper } from '@medely/base';
import { centsToCurrency } from './centsToCurrency';
import { pluralize } from './text';

export type JobBillingForTotalHours = Pick<IJobBilling, 'total_hours'>;
export const hoursFromJobBillings = (jobBillings: JobBillingForTotalHours[]) =>
  Math.round((jobBillings.reduce((acc, jb) => acc + (jb?.total_hours || 0), 0) ?? 0) * 100) / 100;

export type JobBillingForShiftTotals = Pick<IJobBilling, 'job_id'> & JobBillingForTotalHours;
export const shiftTotalsFromJobBillings = (jobBillings: JobBillingForShiftTotals[]) => {
  const hours = hoursFromJobBillings(jobBillings);
  const numJobs = uniqBy(jobBillings, 'job_id').length;

  return {
    shiftCount: numJobs,
    hours,
  };
};

export const splitAmountsByStatus = (
  jobBillings: (Pick<IJobBilling, 'id' | 'category' | 'payout_total_amount_cents'> & {
    job: Pick<IJob, 'status'> & { isJobSplit: boolean };
    payout?: Pick<IPayout, 'status'> | null;
    disputed_job_billings?: { id: number }[] | null;
  })[],
  expenseAdjustments: (Pick<IExpenseAdjustment, 'amount_cents'> & {
    payout?: Pick<IPayout, 'status'> | null;
  })[],
  isSplitPayEnabled: boolean,
) => {
  const breakdown = {
    payout: 0,
    inReview: 0,
    disputed: 0,
    waitingForPayout: 0,
  };

  jobBillings.forEach((jb) => {
    if (!!jb.payout) {
      breakdown.payout += jb.payout_total_amount_cents;
    } else {
      if (isSplitPayEnabled && jb.job.isJobSplit) {
        if (jb.job.status === 'disputed' && !isEmpty(jb.disputed_job_billings)) {
          breakdown.disputed += jb.payout_total_amount_cents;
        } else if (jb.job.status === 'held_for_dispute_review' && jb.category === 'disputable') {
          breakdown.inReview += jb.payout_total_amount_cents;
        } else if (jb.category === 'standard') {
          // job_billings will not be paid unless they're standard.
          breakdown.waitingForPayout += jb.payout_total_amount_cents;
        }
      } else {
        if (jb.job.status === 'disputed') {
          breakdown.disputed += jb.payout_total_amount_cents;
        } else if (jb.job.status === 'held_for_dispute_review') {
          breakdown.inReview += jb.payout_total_amount_cents;
        } else {
          breakdown.waitingForPayout += jb.payout_total_amount_cents;
        }
      }
    }
  });

  expenseAdjustments.forEach((adjustment) => {
    if (!adjustment.payout) {
      breakdown.waitingForPayout += adjustment.amount_cents;
    } else {
      breakdown.payout += adjustment.amount_cents;
    }
  });

  return breakdown;
};

export const pendingHours = (
  jobBillings: (Pick<IJobBilling, 'id' | 'total_hours'> & {
    payout?: Pick<IPayout, 'status'> | null;
  })[],
) => {
  return hoursFromJobBillings(jobBillings.filter((jb) => !jb.payout));
};

export type JobBillingBreakdown = {
  key: string;
  label: string;
  description?: string;
  total: string;
};
export type JobForJobBillingBreakdown = Pick<
  IJob,
  | 'is_w2'
  | 'payout_base_hourly_rate_cents'
  | 'payout_double_overtime_multiplier'
  | 'payout_on_call_hourly_rate_cents'
  | 'payout_overtime_multiplier'
  | 'payout_taxable_hourly_rate_cents'
> & {
  job_billing_sums?: Pick<
    IJobBillingSums,
    | 'payout_callback_amount_cents'
    | 'payout_callback_hours'
    | 'payout_regular_amount_cents'
    | 'payout_regular_hours'
    | 'payout_total_amount_cents'
    | 'payout_overtime_amount_cents'
    | 'payout_overtime_hours'
    | 'payout_daily_overtime_amount_cents'
    | 'payout_daily_overtime_hours'
    | 'payout_weekly_overtime_amount_cents'
    | 'payout_weekly_overtime_hours'
    | 'payout_double_amount_cents'
    | 'payout_double_hours'
    | 'total_on_call_hours'
  >;
};

export function breakdownJobByRate(job: JobForJobBillingBreakdown): JobBillingBreakdown[] {
  const {
    is_w2,
    job_billing_sums,
    payout_base_hourly_rate_cents,
    payout_overtime_multiplier,
    payout_taxable_hourly_rate_cents,
  } = job;
  const fixedRate = payout_overtime_multiplier === 1;

  if (!job_billing_sums) {
    return [];
  }

  const res: JobBillingBreakdown[] = [];

  const getDescription = (hours: number, rate: string | null): string =>
    `${pluralize(hours, 'hour')} x ${rate || ''}`;

  const addRateInfo = (
    key: string,
    label: string,
    hours: number,
    rate: string | null,
    amount: string,
  ): void => {
    if (!rate) {
      rate = '';
    }

    res.push({
      key,
      label,
      description: getDescription(hours, rate),
      total: amount,
    });
  };

  const helper = new PayoutJobBillingHelper(job as IPayoutBillingHelper);

  // Regular rate
  if (job_billing_sums.payout_regular_amount_cents) {
    const { hours, rate, amount } = helper.getRegularRateInfo();
    addRateInfo('regular_rate', 'Regular rate', hours, rate, amount);
  }
  // Callback rate
  else if (job_billing_sums.payout_callback_amount_cents) {
    const callbackRate = Math.floor(
      job_billing_sums.payout_callback_amount_cents / job_billing_sums.payout_callback_hours,
    );
    addRateInfo(
      'callback_rate',
      'Call back rate',
      job_billing_sums.payout_callback_hours,
      centsToCurrency(callbackRate),
      centsToCurrency(job_billing_sums.payout_callback_amount_cents),
    );
  }
  // On-call rate
  else if (job_billing_sums.total_on_call_hours) {
    const { hours, rate, amount } = helper.getOnCallRateInfo();
    addRateInfo('oncall_rate', 'On call rate', hours, rate, amount);
  }

  // Overtime rates
  const overtimeRateLabel = getBaseOTLabel({ isW2: is_w2, abbreviate: false }).replace(
    /( rate)?$/,
    ' rate',
  );

  // Daily overtime
  if (job_billing_sums.payout_daily_overtime_amount_cents && !fixedRate) {
    const dailyHelper = new PayoutJobBillingHelper({
      ...job,
      job_billing_sums: {
        ...job.job_billing_sums,
        payout_overtime_hours: job_billing_sums.payout_daily_overtime_hours,
        payout_overtime_amount_cents: job_billing_sums.payout_daily_overtime_amount_cents,
      },
    } as IPayoutBillingHelper);
    const { hours, rate, amount } = dailyHelper.getOvertimeRateInfo();
    addRateInfo('ot1_rate', overtimeRateLabel, hours, rate, amount);
  }

  // Overtime
  else if (
    job_billing_sums.payout_overtime_amount_cents &&
    !job_billing_sums.payout_weekly_overtime_amount_cents &&
    !fixedRate
  ) {
    const { hours, rate, amount } = helper.getOvertimeRateInfo();
    addRateInfo('ot1_rate', overtimeRateLabel, hours, rate, amount);
  }

  // Double overtime
  if (job_billing_sums.payout_double_amount_cents) {
    const { hours, rate, amount } = helper.getDoubleRateInfo();
    addRateInfo('ot2_rate', overtimeRateLabel, hours, rate, amount);
  }

  // Weekly overtime
  if (job_billing_sums.payout_weekly_overtime_amount_cents) {
    const baseRate = is_w2 ? payout_taxable_hourly_rate_cents : payout_base_hourly_rate_cents;
    addRateInfo(
      'ot3_rate',
      `${overtimeRateLabel} (Weekly)`,
      job_billing_sums.payout_weekly_overtime_hours,
      centsToCurrency(Math.floor(baseRate * payout_overtime_multiplier)),
      centsToCurrency(job_billing_sums.payout_weekly_overtime_amount_cents),
    );
  }

  return res;
}
