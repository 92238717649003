import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { firebaseAuth } from '@mp';
import { GraphQLClient, RequestDocument } from 'graphql-request';

const REACT_APP_COMMIT_SHA = process.env.REACT_APP_COMMIT_SHA;

const gqlRequest = async <T = any>(query: RequestDocument | TypedDocumentNode, variables = {}) => {
  const token = await firebaseAuth.currentUser?.getIdToken();
  const client = new GraphQLClient(
    `${process.env.REACT_APP_GRAPHQL_URL || process.env.EXPO_PUBLIC_GRAPHQL_URL}/graphql`,
    {
      headers: {
        ...(token ? { authorization: `Bearer ${token}` } : {}),
        frontend_version: REACT_APP_COMMIT_SHA ?? 'unknown',
      },
    },
  );

  return client.request<T>(query, variables);
};

export default gqlRequest;
