import { axios } from 'utils/networkRequests';
import { useCallback } from 'react';
import merge from 'lodash/merge';
import { eventTrackingURL, TrackedEvents, debounceLeading } from '@mp';
import { useUtmQueryParams } from 'hooks/useUtmQueryParams';
import { useTrackingQueryParams } from 'hooks/useTrackingQueryParams';
import { useMutation } from '@tanstack/react-query';

type CustomObject = Record<string, [] | boolean | number | object | string>;

type UseTrackedEventProps = {
  event: TrackedEvents;
  itemId?: number;
  metadata?: CustomObject;
  onSuccess?: <TResponse, TOnSuccess>(response: TResponse) => TOnSuccess | void;
  queryParams?: CustomObject;
};

type UseTrackedEventReturn = (overrideParams?: CustomObject) => void;

const DEFAULT_DEBOUNCE_TIME = 500;
const RETRY_ATTEMPTS = 3;

export function useTrackedEvent({
  event,
  itemId,
  metadata,
  onSuccess,
  queryParams: propParams,
}: UseTrackedEventProps): UseTrackedEventReturn {
  const URL = eventTrackingURL({ itemId, event });
  const { utm } = useUtmQueryParams();
  const { queryParams } = useTrackingQueryParams();
  const params = merge({ query_params: { ...queryParams, ...metadata, utm } }, propParams);

  const { mutate } = useMutation({
    mutationKey: [URL, params],
    mutationFn: (overrideParams) => axios.post(URL, merge({}, params, overrideParams)),
    onSuccess,
    retry: RETRY_ATTEMPTS,
  });

  const trackEvent = useCallback(debounceLeading(mutate, DEFAULT_DEBOUNCE_TIME), [URL, params]);

  return trackEvent;
}

export default useTrackedEvent;
