import { useCondition } from '@mp';
import useDialog from '@src/hooks/useDialog';
import MissingConditionDialog from './MissingConditionDialog';
import React from 'react';

type PromptForNewConditionProps = {
  conditionId: number;
};

export const PromptForNewCondition = ({ conditionId }: PromptForNewConditionProps) => {
  const { condition } = useCondition(conditionId);
  const { open, closeDialog } = useDialog(true);
  if (!condition) {
    return null;
  }
  return <MissingConditionDialog open={open} onClose={closeDialog} condition={condition} />;
};
