import { Box, Card, Heading, Text, Button, useFirebaseContext } from '@medely/ui-kit';
import React from 'react';

export const DeactivatedSaasCard = () => {
  const { logout } = useFirebaseContext();
  const handleSaasApplyToWork = () => logout?.('/sign-up');

  return (
    <Card>
      <Heading size="s">Interested in Medely?</Heading>
      <Box py={2}>
        <Text size="l">
          Medely gives Nurses and Allied Pros the flexibility to book jobs and assignments on-demand
        </Text>
      </Box>
      <Button onClick={handleSaasApplyToWork} fullWidth>
        Apply to Work
      </Button>
    </Card>
  );
};
