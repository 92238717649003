/* eslint-disable deprecation/deprecation */
import {
  ICondition,
  IProfessionalQualification,
  IProfessionalQualificationAttribute,
  IQualification,
  IQualificationAttribute,
} from '@medely/types';

export const SUPPORTED_INPUT_SOURCES = ['user', 'professional', 'agency'];

export type AutomationQualificationAttributeT = IQualificationAttribute & {
  automationId?: number;
};

export type AutomationQualificationT = Partial<IQualification> & {
  qualification_attributes?: AutomationQualificationAttributeT[];
};

export type ConditionFormValues = {
  id: number;
  label: string;
  qualifications: Partial<IQualification>[];
};

const shouldUpdateQualificationAttribute = (
  qualificationAttribute: AutomationQualificationAttributeT,
  selectedAutomationId: number,
) => {
  const hasAutomation = !!selectedAutomationId;

  return hasAutomation
    ? qualificationAttribute?.automationId === selectedAutomationId
    : !qualificationAttribute?.automationId;
};

const filterQualifications = (
  qualification: AutomationQualificationT,
  omitNonUserInput: boolean,
  selectedAutomationId: number,
) => {
  const hasAutomation = !!selectedAutomationId;

  const pqAttributes =
    omitNonUserInput && !hasAutomation
      ? qualification.qualification_attributes.filter((pq) =>
          SUPPORTED_INPUT_SOURCES.includes(pq.input_source),
        )
      : qualification.qualification_attributes.filter((qualificationAttribute) =>
          shouldUpdateQualificationAttribute(qualificationAttribute, selectedAutomationId),
        );

  return {
    qualification_automation_id: hasAutomation ? selectedAutomationId : undefined,
    qualification_id: qualification.id,
    professional_qualification_attributes_attributes:
      buildProfessionalQualificationAttributes(pqAttributes),
  };
};

export const buildPayloadFromConditionForm = (
  formValues: ConditionFormValues,
  atIndex = 0,
  omitNonUserInput = false,
  selectedAutomationId = 0,
) => {
  const formattedQualification = filterQualifications(
    formValues.qualifications[atIndex],
    omitNonUserInput,
    selectedAutomationId,
  );

  return { professional_qualification: formattedQualification };
};

type ProfessionalQualificationFormValues = {
  qualification: Partial<IQualification>;
  qualification_automation_id?: number;
};

export const buildPayloadFromQualificationForm = (
  formValues: ProfessionalQualificationFormValues,
) => {
  const selectedAutomationId = formValues.qualification_automation_id;
  const hasAutomation = !!selectedAutomationId;
  const qualificationAttributes = formValues.qualification.qualification_attributes.filter(
    (qualificationAttribute) =>
      shouldUpdateQualificationAttribute(qualificationAttribute, selectedAutomationId),
  );

  const formattedQualification = {
    qualification_automation_id: hasAutomation ? selectedAutomationId : undefined,
    qualification_id: formValues.qualification.id,
    professional_qualification_attributes_attributes:
      buildProfessionalQualificationAttributes(qualificationAttributes),
  };

  return { professional_qualification: formattedQualification };
};

const buildProfessionalQualificationAttributes = (
  qualificationAttributes: IQualificationAttribute[],
) => {
  const professionalQualificationAttributes: (Partial<IProfessionalQualificationAttribute> & {
    extension?: string;
  })[] = [];
  qualificationAttributes.forEach((qa) => {
    const isImage = qa.field_type.includes('image');
    if (isImage && !!qa.value?.encodedFile) {
      professionalQualificationAttributes.push({
        qualification_attribute_id: qa.id,
        value: qa.value.encodedFile,
        extension: qa.value.extension,
      });
    } else if (!isImage && !!qa.value) {
      professionalQualificationAttributes.push({
        qualification_attribute_id: qa.id,
        value: qa.value,
      });
    }
  });
  return professionalQualificationAttributes;
};

export const typeformIdFromCondition = (condition: ICondition) => {
  const typeformQualification = condition.qualifications.find(
    (qualification) => qualification.qualification_type === 'typeform',
  );
  if (!typeformQualification) {
    throw new Error('Condition does not have an associated typeform qualification');
  }

  const typeformAttribute = typeformQualification.qualification_attributes.find(
    (attribute) => attribute.field_type === 'external_id',
  );
  if (!typeformAttribute) {
    throw new Error('Qualification is missing external_id attribute');
  }
  return typeformAttribute.data.id;
};

export const typeformIdFromQualification = (qualification: IProfessionalQualification) => {
  const typeformAttribute = qualification?.professional_qualification_attributes?.find(
    (attribute) => attribute?.qualification_attribute?.field_type === 'external_id',
  );
  if (!typeformAttribute) {
    throw new Error('Qualification is missing external_id attribute');
  }
  return typeformAttribute?.value;
};

export const sortQualificationAttributes = (
  a: IQualificationAttribute,
  b: IQualificationAttribute,
) => {
  // Will not change attributes
  // returns -1 if a > b
  // returns 1 if b > a
  // returns 0 if a = b
  const fieldTypes = [
    'license_number',
    'state',
    'expiration_date',
    'boolean',
    'image_front',
    'image_back',
    'pdf',
    'string',
    'date',
  ];
  return a.field_type === b.field_type
    ? a.id - b.id
    : fieldTypes.indexOf(a.field_type) - fieldTypes.indexOf(b.field_type);
};

const formattedQualificationAutomation = (automation) =>
  automation.qualification_automation_attributes.map((automationAttribute) => ({
    automationId: automation.id,
    ...automationAttribute.qualification_attribute,
    required: automationAttribute.required,
  }));

export const buildQualificationAttributes = (qualification) => {
  const automations = qualification?.qualification_automations ?? [];
  const formattedAutomations = automations?.flatMap(formattedQualificationAutomation);

  return {
    ...qualification,
    qualification_attributes: [...qualification.qualification_attributes, ...formattedAutomations],
  };
};

export const buildFormCondition = (condition: ICondition) => ({
  ...condition,
  qualifications: condition.qualifications.map(buildQualificationAttributes),
});
