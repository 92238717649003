import { IJob } from '@medely/types';
import moment from 'moment-timezone';
import { useQuery } from '@tanstack/react-query';
import { useNetworkRequest } from './useNetworkRequest';

const DEFAULT_IMMEDIATE_WINDOW_HOURS = 72;

const startsWithinWindow = (startsTime: string) => {
  const timeThreshold = moment().add(DEFAULT_IMMEDIATE_WINDOW_HOURS, 'hours');
  return moment(startsTime).isBefore(timeThreshold);
};

export const useImmediateExpiringConditions = (job: IJob) => {
  const { axios } = useNetworkRequest();
  const enabled = startsWithinWindow(job.current_starts_time);

  const fetchImmediateExpiringConditionIds = async (jobId: number): Promise<Array<number>> => {
    const {
      data: { condition_ids: ids },
    } = await axios.post(`/v3/professional/jobs/${jobId}/immediate_expiring_condition_ids`);

    return ids;
  };

  // TODO: Better understand the cost of this query and when to re-fire it CORE-4518
  const { data, isLoading, refetch } = useQuery({
    enabled,
    queryKey: ['fetchImmediateExpiringConditionIds', job.id],
    queryFn: () => fetchImmediateExpiringConditionIds(job.id),
  });

  return {
    immediateExpiringConditionIds: data ?? [],
    isLoading: isLoading && enabled,
    refetch,
  };
};
