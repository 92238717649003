import { BottomSheetDialog } from '@medely/web-components';
import useDevice from '../../../hooks/useDevice.web';
import React from 'react';
import { RecommendedSpecialtiesInfo } from './RecommendedSpecialtiesInfo';
import { RecommendedSpecialtiesModalProps } from './interface';

export const RecommendedSpecialtiesModal = ({
  open,
  onClose,
  options,
  handleContinueClick,
}: RecommendedSpecialtiesModalProps) => {
  const isDesktop = useDevice() === 'desktop';

  return (
    <BottomSheetDialog
      open={open}
      onClose={onClose}
      title="Recommended specialties"
      disableRestoreFocus
      sx={{ alignSelf: isDesktop ? 'center' : 'end' }}
      content={
        <RecommendedSpecialtiesInfo
          options={options}
          handleContinueClick={handleContinueClick}
          onClose={onClose}
        />
      }
      isDesktop={isDesktop}
    />
  );
};
