import { ICondition } from '@medely/types';
import { OnboardingStep, OnboardingStepName } from 'components/onboarding/OnboardingList';
import useCurrentUser from './useCurrentUser';
import useMissingOnboardingConditions, {
  type UseMissingOnboardingConditionsArgs,
} from './useMissingOnboardingConditions';
import useProfessionalQualifications, {
  type UseProfessionalQualificationsArgs,
} from './useProfessionalQualifications';
import {
  ADDRESS_CONDITION_SLUG,
  DIRECT_DEPOSIT_CONDITION_SLUG,
  EXPERIENCE_CONDITION_SLUG,
} from '@src/components/onboarding/constants';
import useSkillsAssessmentsOnboardingStep from './useSkillsAssessmentsOnboardingStep';
import useSkillsAssessments from './useSkillsAssessments';
import { evaluateBgCheckStep, evaluateCredentialsStep } from '@src/components/onboarding/utils';

export const missingConditionSlugNotPresent = (conditions: ICondition[], slug: string) =>
  !conditions.find((condition) => condition.slug === slug);

export const isSkillsAssessmentCondition = (condition: ICondition) =>
  condition.qualifications.some((qualification) => qualification.qualification_type === 'typeform');

export type UseOnboardingStepsArgs = Pick<UseProfessionalQualificationsArgs, 'refetch'> &
  Pick<UseMissingOnboardingConditionsArgs, 'refetch'>;

type UseOnboardingStepsResult = {
  isLoading: boolean;
  steps: OnboardingStep[];
  completedSteps: OnboardingStep[];
  remainingSteps: OnboardingStep[];
};

const useOnboardingSteps = ({ refetch }: UseOnboardingStepsArgs = {}): UseOnboardingStepsResult => {
  const { currentUser, isSaas, isAgency } = useCurrentUser();
  const { conditions: allMissingOnboardingConditions, isLoading: isConditionsLoading } =
    useMissingOnboardingConditions({
      refetch,
    });
  const { allProfessionalQualifications, isLoading: isQualificationsLoading } =
    useProfessionalQualifications({ refetch });
  const { completedSkillsAssessments, missingSkillsAssessments } = useSkillsAssessments({
    statuses: ['approved', 'pending'],
    refetch: true,
  });
  const { onboardingSkillsAssessmentsHint } = useSkillsAssessmentsOnboardingStep({
    completedSkillsAssessments,
    missingSkillsAssessments,
  });

  const { bgCheckIsComplete, bgCheckAwaitingReview } = evaluateBgCheckStep({
    allMissingOnboardingConditions,
    bgCheckProfessionalQualifications: currentUser?.professional?.background_check_pqs || [],
  });

  const {
    onboardingConditionsAreComplete,
    onboardingConditionsAreAwaitingReview,
    onboardingConditionsHint,
  } = evaluateCredentialsStep({
    allMissingOnboardingConditions,
    allProfessionalQualifications,
  });

  const STEPS: OnboardingStep[] = [
    {
      name: OnboardingStepName.Address,
      label: 'Home address',
      path: '/profile/personal',
      isComplete: missingConditionSlugNotPresent(
        allMissingOnboardingConditions,
        ADDRESS_CONDITION_SLUG,
      ),
      isAwaitingReview: false,
    },
    {
      name: OnboardingStepName.Credentials,
      label: 'Credentials',
      path: '/credentials/status',
      hint: onboardingConditionsHint,
      isComplete: onboardingConditionsAreComplete,
      isAwaitingReview: onboardingConditionsAreAwaitingReview,
    },
    {
      name: OnboardingStepName.SkillsAssessments,
      label: 'Skills assessments',
      path: '/credentials/skills_assessments',
      hint: onboardingSkillsAssessmentsHint,
      isComplete: !allMissingOnboardingConditions.find(isSkillsAssessmentCondition),
      isAwaitingReview: false,
    },
    {
      name: OnboardingStepName.BackgroundCheck,
      label: 'Background check',
      path: '/onboarding/background_check/disclosure',
      isComplete: bgCheckIsComplete,
      isAwaitingReview: bgCheckAwaitingReview,
      disabled: bgCheckAwaitingReview,
    },
    {
      name: OnboardingStepName.DirectDeposit,
      label: 'Direct deposit',
      path: currentUser?.professional?.background_checks?.length
        ? '/settings/financial'
        : '/onboarding/background_check/disclosure?for_direct_deposit=true',
      isComplete: missingConditionSlugNotPresent(
        allMissingOnboardingConditions,
        DIRECT_DEPOSIT_CONDITION_SLUG,
      ),
      isAwaitingReview: false,
    },
    {
      name: OnboardingStepName.Experience,
      label: 'Work experience',
      path: '/profile/resume',
      isComplete: missingConditionSlugNotPresent(
        allMissingOnboardingConditions,
        EXPERIENCE_CONDITION_SLUG,
      ),
      isAwaitingReview: false,
    },
    {
      name: OnboardingStepName.WorkExperience,
      label: 'Work experience',
      path: '/profile/work-experience',
      isComplete: !!currentUser?.professional?.resume,
      isAwaitingReview: false,
    },
  ];

  const MARKETPLACE_STEPS = STEPS.filter((step) =>
    [
      OnboardingStepName.Address,
      OnboardingStepName.Credentials,
      OnboardingStepName.SkillsAssessments,
      OnboardingStepName.BackgroundCheck,
      OnboardingStepName.DirectDeposit,
      OnboardingStepName.WorkExperience,
    ].includes(step.name),
  );

  const SAAS_STEPS = STEPS.filter((step) =>
    [OnboardingStepName.Credentials, OnboardingStepName.SkillsAssessments].includes(step.name),
  );

  const AGENCY_STEPS = STEPS.filter((step) =>
    [
      OnboardingStepName.Address,
      OnboardingStepName.Credentials,
      OnboardingStepName.SkillsAssessments,
      OnboardingStepName.Experience,
    ].includes(step.name),
  );

  const completedSteps: OnboardingStep[] = [];
  const remainingSteps: OnboardingStep[] = [];
  const onboardingSteps = (() => {
    if (isSaas) {
      return SAAS_STEPS;
    }
    if (isAgency) {
      return AGENCY_STEPS;
    }
    return MARKETPLACE_STEPS;
  })();

  onboardingSteps.forEach((step) => {
    const complete = step.isComplete;

    if (complete) {
      completedSteps.push(step);
    } else {
      remainingSteps.push(step);
    }
  });

  return {
    isLoading: isConditionsLoading || isQualificationsLoading,
    steps: onboardingSteps,
    completedSteps,
    remainingSteps,
  };
};

export default useOnboardingSteps;
