import { Box, Label, Text } from '@medely/ui-kit';
import React from 'react';
import useDialog from '@mp/hooks/useDialog';
import { WhatToExpectModal } from '../whatToExpectModal/WhatToExpectModal';
import { HelpCenterLink } from '../helpCenterLink';
import { useCurrentUser } from '../../hooks';

type JobBlurbsProps = {
  isNewToMedely: boolean;
  isNewToFacility: boolean;
  isFavorite: boolean;
};

export const JobBlurbs = ({ isNewToMedely, isNewToFacility, isFavorite }: JobBlurbsProps) => {
  const { open, closeDialog, openDialog } = useDialog();
  const { isAgency, isSaas } = useCurrentUser();

  if (isNewToMedely && !isSaas) {
    return (
      <>
        <Label size="m" color="text.primary">
          New to Medely?
        </Label>
        <Box mt={0.5} mb={1}>
          <Text size="m" color="text.primary">
            You probably have a few questions. We’re here to help you succeed.
          </Text>
          {isAgency ? (
            <Box mt={2}>
              <HelpCenterLink configKey="learnHowMedelyWorksAgency" text="Learn how Medely works" />
            </Box>
          ) : (
            <>
              <Box my={2}>
                <Box onClick={() => openDialog()}>
                  <Text size="m" color="state.teal.primary">
                    View what to expect
                  </Text>
                </Box>
              </Box>
              <HelpCenterLink configKey="setYourselfUpForSuccess" text="Learn how Medely works" />
            </>
          )}
        </Box>
        <WhatToExpectModal open={open} onClose={closeDialog} />
      </>
    );
  } else if (isNewToFacility) {
    return (
      <>
        <Label size="m" color="text.primary">
          This is a new facility for you
        </Label>
        <Box mt={0.5} mb={1}>
          <Text size="m" color="text.primary">
            Please review the facility instructions and location before your shift starts so you can
            be as prepared as possible.
          </Text>
        </Box>
      </>
    );
  } else if (isFavorite && !isSaas) {
    return (
      <>
        <Label size="m" color="text.primary">
          You&apos;re a favorite of this facility
        </Label>
        <Box mt={0.5} mb={1}>
          <Text size="m" color="text.primary">
            As a favorite of this facility, you were given priority in booking this shift.
          </Text>
        </Box>
      </>
    );
  } else {
    return null;
  }
};
