import { Box } from '@medely/web-components';
import React from 'react';
import ChatMenu from '../Chat';
import TopBarMobileNavigation from './TopBarMobileNavigation';
import TopBarMobileTitle from './TopBarMobileTitle';
import { useWebView } from '@mp';

const TopBarMobile = (): React.ReactElement | null => {
  const { isWebView } = useWebView();

  if (isWebView) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', py: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TopBarMobileNavigation />
        <TopBarMobileTitle />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <ChatMenu />
      </Box>
    </Box>
  );
};

export default TopBarMobile;
