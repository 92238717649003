import { IProfessionalQualificationAttribute } from '@medely/types';
import useGraphQLRequest from './useGraphQLRequest';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { GET_PROFESSIONAL_QUALIFICATION_ATTRIBUTE } from '@mp';

const useProfessionalQualificationAttribute = (id: number) => {
  const { request } = useGraphQLRequest();

  const fetchProfessionalQualificationAttribute = async ({
    queryKey: [_entity, id],
  }: QueryFunctionContext<[unknown, number]>): Promise<IProfessionalQualificationAttribute> => {
    const { professionalQualificationAttribute } = await request(
      GET_PROFESSIONAL_QUALIFICATION_ATTRIBUTE,
      { id },
    );
    return professionalQualificationAttribute;
  };

  const { data, isLoading } = useQuery(
    ['professionalQualificationAttribute', id],
    fetchProfessionalQualificationAttribute,
    { enabled: !!id },
  );

  return { data, isLoading };
};

export default useProfessionalQualificationAttribute;
