import { MedelyDateTime } from '@medely/date-time-tools';
import {
  IJobPriceDifferentials,
  IJob,
  JobStatusEnum as JobStatus,
  ILocation,
  IJobProfile,
} from '@medely/types';
import { InfoBadgeColor } from '@medely/ui-kit';

export const isHolidayDifferential = (differential: IJobPriceDifferentials): boolean =>
  differential.slug === 'holiday' && differential.recommendation_used;

export const historicalShiftBadgeText = ({ status }: IJob): string => {
  switch (status) {
    case JobStatus.CanceledWithFee:
      return 'Canceled';
    case JobStatus.ClockedOut:
    case JobStatus.Completed:
      return 'Completed';
    case JobStatus.Disputed:
      return 'Disputed';
    case JobStatus.HeldForDisputeReview:
      return 'In review';
    case JobStatus.LateCancellation:
    case JobStatus.NoShow:
      return 'No show';
    case JobStatus.MissedShift:
      return 'Missed shift';
    default:
      return '';
  }
};

export const historicalShiftBadgeColor = ({ status }: IJob): InfoBadgeColor => {
  switch (status) {
    case JobStatus.CanceledWithFee:
    case JobStatus.MissedShift:
      return 'default';
    case JobStatus.ClockedOut:
    case JobStatus.Completed:
      return 'success';
    case JobStatus.Disputed:
    case JobStatus.LateCancellation:
    case JobStatus.NoShow:
      return 'error';
    case JobStatus.HeldForDisputeReview:
      return 'warning';
    default:
      return 'default';
  }
};

const WORKING_STATUSES = ['booked', 'clocked_in', 'clocked_out'];

export type JobBonusCentsProps = {
  total_payout_bonus_cents?: number;
  status?: string;
  job_profile_for_account_id?: Pick<IJobProfile, 'payout_facility_bonus_cents' | 'active'> | null;
};

export const jobBonusCents = (job: JobBonusCentsProps) => {
  const jobProfile = job.job_profile_for_account_id;
  const newBonusField = jobProfile?.payout_facility_bonus_cents || 0;
  const oldBonusField = job?.total_payout_bonus_cents || 0;

  if (job.status && WORKING_STATUSES.includes(job.status)) {
    return oldBonusField;
  } else {
    return jobProfile?.active ? oldBonusField : newBonusField + oldBonusField;
  }
};

export type JobForIsOvernight = Pick<IJob, 'current_ends_time' | 'current_starts_time'> & {
  location: Pick<ILocation, 'timezone_lookup'>;
};
export const isOvernight = (job: JobForIsOvernight): boolean => {
  const tz = job.location.timezone_lookup || 'system';
  const startTime = new MedelyDateTime(job.current_starts_time, { tz });
  const endTime = new MedelyDateTime(job.current_ends_time, { tz });

  return endTime.dateShort !== startTime.dateShort;
};
