import React, { useCallback } from 'react';
import { Button, Icon } from '@medely/ui-kit';
import { useMaskedPhoneNumberMutations } from '@mp/hooks';
import useDialog from '@mp/hooks/useDialog';
import { CallFacilityDialog } from './CallFacilityDialog';

interface ContactFacilityProps {
  phone?: string;
  contactableId: number;
  type: 'job' | 'assignment';
  smallIcon?: boolean;
}

const ContactFacility = ({
  contactableId,
  phone,
  type,
  smallIcon = false,
}: ContactFacilityProps): React.ReactElement => {
  const { requestJobMaskedNumber, requestAssignmentMaskedNumber, isLoading } =
    useMaskedPhoneNumberMutations();
  const { open, openDialog, closeDialog } = useDialog();

  const handleCall = useCallback(() => {
    if (!phone) {
      if (type === 'job') {
        requestJobMaskedNumber(contactableId, { onSuccess: openDialog });
      } else {
        requestAssignmentMaskedNumber(contactableId, {
          onSuccess: openDialog,
        });
      }
    } else {
      openDialog();
    }
  }, [contactableId, phone]);

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        loading={isLoading}
        onClick={handleCall}
        testId="contact-facility-button"
        startIcon={
          <Icon
            name="phone"
            iconSize={smallIcon ? '14px' : '20px'}
            color="text.primary"
            variant="custom"
          />
        }
      >
        Call Facility
      </Button>
      <CallFacilityDialog open={open} onClose={closeDialog} phone={phone} />
    </>
  );
};

export default ContactFacility;
