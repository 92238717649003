import { Box, Card, HStack, Icon, Label, VStack, Text, LabelProps } from '@medely/ui-kit';
import React from 'react';
import { CircularAvatar } from '../icons/CircularAvatar';
import { HelpCenterLink } from '../helpCenterLink';

type CardLayoutProps = {
  title: LabelProps['children'];
  infoText: LabelProps['children'];
  hasDisplayLinks: boolean;
};

export const CardLayout = ({ title, infoText, hasDisplayLinks }: CardLayoutProps) => {
  return (
    <Card>
      <HStack alignItems="center" gap={2} px={1}>
        <CircularAvatar color="state.error.backing-1" width="35px" height="35px">
          <Icon name="light-ban" iconSize="20px" color="state.error.secondary" variant="custom" />
        </CircularAvatar>
        <Label size="l">{title}</Label>
      </HStack>
      <Box py={2} px={1}>
        <Text size="l">{infoText}</Text>
        {hasDisplayLinks && (
          <VStack pt={1}>
            <HelpCenterLink configKey="learnAboutPayouts" text="Review Community Guidelines" />
            <HelpCenterLink configKey="professionalTermsPathname" text="Review Terms of Service" />
          </VStack>
        )}
      </Box>
    </Card>
  );
};
