import { useCurrentUser } from '@mp';
import { MenuItem } from '@medely/web-components';
import React from 'react';
import { SettingsMenuItemsProps } from '../SettingsDropdown';
import { useHistory } from 'react-router';

export const IrpSettingsMenu = ({ handleClose }: SettingsMenuItemsProps) => {
  const { isDeactivated } = useCurrentUser();
  const history = useHistory();
  return (
    <>
      {!isDeactivated && (
        <MenuItem
          onClick={() => {
            history.push('/profile');
            handleClose();
          }}
        >
          My Profile
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          history.push('/settings');
          handleClose();
        }}
      >
        Settings
      </MenuItem>
    </>
  );
};
