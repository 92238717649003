import Onboarding from '@src/routes/onboarding/Onboarding';
import React, { useEffect } from 'react';
import { useWebView } from '@mp';
import { Box } from '@medely/ui-kit';

const OnboardingWebViewEmbedded = () => {
  const { setIsWebView } = useWebView();

  useEffect(() => {
    setIsWebView(true);
  }, []);

  return (
    <Box p={1}>
      <Onboarding />
    </Box>
  );
};

export default OnboardingWebViewEmbedded;
