import React from 'react';
import { HStack, Heading, Icon, Label } from '@medely/ui-kit';
import { useHistory } from 'react-router';

interface TopBarCleanProps {
  title: string;
  path?: string;
}

const TopBarClean = ({ title, path }: TopBarCleanProps): React.ReactElement => {
  const history = useHistory();
  return (
    <HStack gap={4} justifyContent="center" alignItems="center" width="100%">
      {path && (
        <HStack
          onClick={() => {
            history.push(path);
          }}
          alignItems="center"
          position="absolute"
          left={0}
        >
          <Icon name="chevron-left" boxSize="32px" color="primary" variant="custom" />
          <Label size="l" color="state.teal.primary">
            Back
          </Label>
        </HStack>
      )}
      <Heading size="s">{title}</Heading>
    </HStack>
  );
};

export default TopBarClean;
