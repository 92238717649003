import { IState } from '@medely/types';
import { GET_STATE } from '@mp';
import { useQuery } from '@tanstack/react-query';
import useGraphQLRequest from './useGraphQLRequest';

/**
 * dont be confused...this has nothing to do with react state,
 * this hook fetches a State record from our db (as in one of the
 * United ones...)
 */
const useStates = (id: number): { state: IState; isLoading: boolean } => {
  const { request } = useGraphQLRequest();

  const fetchStates = async () => {
    const { state } = await request(GET_STATE, { id });
    return state;
  };
  const { data = [], isLoading } = useQuery(['state', id], fetchStates);
  return {
    state: data,
    isLoading,
  };
};

export default useStates;
