import React, { ReactElement, ReactNode } from 'react';
import { Box, HStack, Label } from '@medely/ui-kit';

interface IJobDetailsListItemProps {
  children?: ReactNode;
  icon?: ReactElement;
  title?: string;
  titleNode?: ReactNode;
  small?: boolean;
}

export const JobDetailsListItem = ({
  children,
  icon,
  title,
  titleNode,
  small,
}: IJobDetailsListItemProps): ReactElement => {
  return (
    <HStack testId="job-details-list-item">
      <Box pr={icon ? 2 : 0}>{icon}</Box>
      <Box flex={1}>
        {titleNode ?? (title ? <Label size={small ? 'm' : 'l'}>{title}</Label> : null)}
        {children}
      </Box>
    </HStack>
  );
};
