import { BottomSheetDialog, Link } from '@medely/web-components';
import React from 'react';
import { Button } from '@medely/ui-kit';
import useDevice from '../../hooks/useDevice.web';
import { CallFacilityDialogInfo } from './CallFacilityDialogInfo';
type BottomSheetDialogProps = React.ComponentProps<typeof BottomSheetDialog>;

export type CallFacilityDialogProps = {
  open: BottomSheetDialogProps['open'];
  onClose: BottomSheetDialogProps['onClose'];
  phone?: string;
};

export const CallFacilityDialog = ({ open, onClose, phone }: CallFacilityDialogProps) => {
  const isDesktop = useDevice() === 'desktop';

  return (
    <BottomSheetDialog
      open={open}
      onClose={onClose}
      title="Call the Facility"
      sx={{ alignSelf: isDesktop ? 'center' : 'end' }}
      content={<CallFacilityDialogInfo phone={phone} />}
      actions={
        <Link href={`tel:${phone}`}>
          <Button color="primary" variant="contained">
            Call
          </Button>
        </Link>
      }
      isDesktop={isDesktop}
    />
  );
};
