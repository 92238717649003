import { useCurrentUser } from '@mp/hooks';
import { calculateLateCancellation } from '../cancelationUtils';
import { IJob } from '@medely/types';
import { Box, Text, Label } from '@medely/ui-kit';
import React from 'react';
import { ProfileStatsGql } from '../cancellationQueries';
import { useGraphQLQuery } from '@mp/hooks/useGraphQLRequest';
import { ResultOf } from '@graphql-typed-document-node/core';
import { ContentLoader } from '@mp/components/ContentLoader';
import { HelpCenterLink } from '@mp/components/helpCenterLink';

type CancelationContentTextProps = {
  job: IJob;
};

const SubmitText = ({ job }: { job: IJob }) => (
  <Box py={2}>
    <Text size="l" testId="cancelation-content-submit-text-info">
      Please confirm that you would like to cancel Job ID: {job.id} by tapping on the CANCEL JOB
      button below.
    </Text>
    {!!job.assignment && (
      <Box pt={2}>
        <Text size="l" testId="cancelation-content-submit-text-job-assignment">
          This will count as a missed shift but will NOT affect your commitment rate or ability to
          book more jobs outside of this assignment.
        </Text>
      </Box>
    )}
  </Box>
);

const Within30Mins = ({ job }: { job: IJob }) => {
  return (
    <>
      <Label size="l" testId="cancelation-content-within-30-Mins-job-id">
        Job ID: {job.id}
      </Label>
      <SubmitText job={job} />
    </>
  );
};

const Stats = ({ job }: { job: IJob }) => {
  return (
    <>
      <Text size="l">Excessive cancellations may result in account suspension.</Text>
      <SubmitText job={job} />
    </>
  );
};

const NoShow = ({ job }: { job: IJob }) => {
  const { data, isLoading } = useGraphQLQuery<ResultOf<typeof ProfileStatsGql>>({
    operationName: 'GetProfileStatsForCancelationPolicy',
    query: ProfileStatsGql,
    variables: {},
  });
  const profileStats = data?.profileStats;

  const { isAgency } = useCurrentUser();
  const link = isAgency ? 'cancellationsAndNoShowAgency' : 'cancellationsAndNoShow';
  return (
    <ContentLoader loading={isLoading}>
      <>
        <Box mt={2}>
          <Label size="l">This will count as a Late Cancellation. </Label>
          <Text size="l">
            You currently have {profileStats?.late_cancellations_count} Late Cancellations and{' '}
            {profileStats?.no_shows_count} No Shows in the past 12 months.{' '}
            <HelpCenterLink text="Click here" url={link} isLarge /> to see if this will affect your
            account status.
          </Text>
        </Box>
        <SubmitText job={job} />
      </>
    </ContentLoader>
  );
};

export const CancelationContentText = ({ job }: CancelationContentTextProps) => {
  const { isSaas } = useCurrentUser();
  const { isBookedWithin30mins, isFromAssignment, showLateCancellationWarning } =
    calculateLateCancellation({
      bookedAt: job.booked_at,
      currentStartsTime: job.current_starts_time,
      jobAssignment: job.assignment,
      updatedInCancellationWindow: job.updated_in_cancellation_window,
    });

  if (isBookedWithin30mins || isFromAssignment) {
    return <Within30Mins job={job} />;
  }
  return showLateCancellationWarning && !isSaas ? <NoShow job={job} /> : <Stats job={job} />;
};
