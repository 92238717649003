import React from 'react';
import { HStack, Label, VStack } from '@medely/ui-kit';
import { Item } from './Timeline';

interface TimelineItemProps {
  item: Item;
  isLast?: boolean;
}

const TimelineStroke = () => {
  return <HStack bgcolor="accent.backing-1" width="2px" flex={1} />;
};

export const TimelineItem = ({ item, isLast = false }: TimelineItemProps) => {
  return (
    <HStack>
      <VStack alignItems="center" mr={2}>
        <HStack
          bgcolor="accent.backing-1"
          width="40px"
          height="40px"
          borderRadius="20px"
          justifyContent="center"
          alignItems="center"
        >
          {item.icon}
        </HStack>
        {isLast ? <></> : <TimelineStroke />}
      </VStack>
      <VStack pb={4} pr={6}>
        {!!item.label && (
          <Label size="m" color="text.primary">
            {item.label}
          </Label>
        )}
        <HStack flexWrap="wrap">{item.description}</HStack>
      </VStack>
    </HStack>
  );
};
