import { BottomSheetDialog } from '@medely/web-components';
import React, { useEffect } from 'react';
import { PositionsDetails } from './PositionsDetails';
import { Button, useAnalytics } from '@medely/ui-kit';
import useDevice from '../../hooks/useDevice.web';
import { IPosition } from '@medely/types';
import { useCurrentUser } from '@mp/hooks';

type BottomSheetDialogProps = React.ComponentProps<typeof BottomSheetDialog>;

export type PositionsDetailsModalProps = {
  open: BottomSheetDialogProps['open'];
  onClose: BottomSheetDialogProps['onClose'];
  positions: IPosition[];
};

export const PositionsDetailsModal = ({ open, onClose, positions }: PositionsDetailsModalProps) => {
  const { currentUser, proType } = useCurrentUser();
  const analytics = useAnalytics();
  const isDesktop = useDevice() === 'desktop';

  useEffect(() => {
    if (!!currentUser && open) {
      analytics.track('Product Application Position Details - Viewed', {
        account_id: currentUser?.id,
        context: 'Product Application Position Details',
        subcontext: null,
        action: 'Viewed',
        pro_type: proType,
      });
    }
  }, [currentUser, open]);

  return (
    <BottomSheetDialog
      open={open}
      onClose={onClose}
      title="Position details"
      disableRestoreFocus
      content={<PositionsDetails positions={positions} />}
      actions={
        <Button fullWidth onClick={onClose} testId="positions-details-modal-close-button">
          Close
        </Button>
      }
      isDesktop={isDesktop}
    />
  );
};
