import { StringParam, NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { USER_SOURCE } from '@mp';

export const useTrackingQueryParams = (): {
  queryParams: {
    os: string;
    SourceTrackedEventID: number;
    position: number;
    list_view_id: number;
  };
  setListViewId: ({ data: { id } }: { data: { id: number } }) => void;
  clearListViewId: () => void;
} => {
  const [{ os, SourceTrackedEventID, position, list_view_id }, setQuery] = useQueryParams({
    os: withDefault(StringParam, USER_SOURCE.other),
    SourceTrackedEventID: NumberParam,
    // position should not be tracked on every instance of assignment view events,
    // for that reason, no default params are used here,
    // instead, it's set at AssignmentsList/JobsList
    position: NumberParam,
    list_view_id: NumberParam,
  });

  const setListViewId = ({ data: { id: list_view_id } }) => setQuery({ list_view_id }, 'replaceIn');
  // Clears ID until new list view POST completes on page change
  const clearListViewId = () => setQuery({ list_view_id: undefined }, 'replaceIn');

  return {
    queryParams: { os, SourceTrackedEventID, position, list_view_id },
    setListViewId,
    clearListViewId,
  };
};
