import React, { createContext, useContext, useState, ReactNode } from 'react';

interface IWebViewContext {
  isWebView: boolean | null;
  setIsWebView: (value: boolean | null) => void;
}

const WebViewContext = createContext<IWebViewContext | undefined>(undefined);

export const useWebView = () => {
  const context = useContext(WebViewContext);
  if (!context) {
    throw new Error('useWebView must be used within a WebViewProvider');
  }
  return context;
};

export const WebViewProvider = ({ children }: { children: ReactNode }) => {
  const [isWebView, setIsWebView] = useState<boolean | null>(null);

  return (
    <WebViewContext.Provider value={{ isWebView, setIsWebView }}>
      {children}
    </WebViewContext.Provider>
  );
};
