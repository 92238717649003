import { isProduction } from './utils/env';

const config = {
  accountPausedTypeformId: isProduction() ? 'NJTgkr' : 'k3EYha',
  adminUrl: process.env.REACT_APP_ADMIN_URL || 'http://localhost:5000',
  assignmentReferralTerms: 'https://help.medely.com/en/articles/4809046-assignment-referrals',
  calendlyAssignmentURL: isProduction()
    ? 'https://calendly.com/phone-screening/assignments'
    : 'https://calendly.com/development-phone-screening/development-phone-screening-2',
  calendlyURL(v2 = false) {
    const team = isProduction() ? 'phone-screening' : 'development-phone-screening';
    const version = v2 ? 'phone-screening-v2' : 'phone-screening';
    return `https://calendly.com/${team}/${version}`;
  },
  facilityUrl: process.env.REACT_APP_FACILITY_URL || 'http://localhost:3500',
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyBFXF0RZ_8RFi62eNEnuNzscb8i60JlsQw',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'medely-staging.firebaseapp.com',
  },
  googleMapsKey: process.env.REACT_APP_MAPS_API_KEY ?? 'AIzaSyB1-c4n-lFxIFF9EhCiJUqISOCdwQsVn1o',
  helloSignId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
  help: {
    url: 'https://help.medely.com',
    agencyUrl: 'https://help-partners.medely.com',
    signing_up: 'https://help.medely.com/collections/127521-signing-up',
    covid_requirements:
      'https://help.medely.com/en/articles/5497289-covid-vaccine-booster-or-test-protocol-when-required-by-state-or-facility',
    communityGuidelines: 'https://help.medely.com/en/articles/4564959-community-guidelines',
    communityResources:
      'https://help.medely.com/en/articles/1674135-professional-community-resources',
    sms: 'https://help.medely.com/en/articles/5322046-sms-terms',
    privacyPolicy: 'https://medely.com/privacy-policy',
    clockingInOut: 'https://help.medely.com/en/articles/103312-clocking-in-out',
    frontlineW2Shifts: 'https://intercom.help/frontline-health/en/articles/8464674-w-2-shifts',
    formFaq: 'https://help.medely.com/en/articles/6644709-530-form-faq',
    stripeRepresentativeTaxInfo:
      'https://help.medely.com/en/articles/4901773-direct-deposit-business',
  },
  itemUrl: process.env.REACT_APP_ITEM_URL,
  payoutExtensions: ['.jpg', '.png', '.doc', '.docx', '.pdf'],
  professionalTermsPathname: 'https://medely.com/professional_terms',
  rollbar: {
    postClientItemKey:
      process.env.REACT_APP_ROLLBAR_CLIENT_KEY || '020d70f3b088491db3b62035212a8eaf',
  },
  root: '',
  segmentWriteKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
  signUpKey: process.env.REACT_APP_SIGNUP_KEY || 'zB9NVETcO9!%',
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  ironclad: {
    accessId: isProduction()
      ? '81e8ee67-11ac-47dc-9128-00fbdf0bf3c8'
      : 'f5c93924-6c70-45c0-97ec-635b7121d1aa',
    groupKeys: {
      scroll: {
        tosAgency: isProduction() ? 'embedded-9z2d3m-0x' : 'embedded-staging-tos-scroll',
        tosMarketplace: isProduction() ? 'embedded-zbxstaihj-copy' : 'embedded-staging-tos-scroll',
      },
      checkbox: {
        tosAgency: isProduction() ? 'embedded-9z2d3m-0x-copy' : 'embedded-staging-tos-checkbox',
        tosMarketplace: isProduction() ? 'embedded-zbxstaihj' : 'embedded-staging-tos-checkbox',
      },
    },
  },
  testTypeform: 'p4ayvY',
};

export default config;
