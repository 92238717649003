import { GET_CONDITIONS } from '@mp';
import { ICondition } from '@medely/types';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { DEFAULT_REFETCH_INTERVAL } from '../constants/graphql';
import useCurrentUser from './useCurrentUser';
import useGraphQLRequest from './useGraphQLRequest';
import useHandleError from './useHandleError';

type UseQueryMissingConditionsInput = {
  assignment_id?: number;
  recommended?: boolean;
  job_id?: number;
  position_ids?: number[];
  professional_id: number;
};

const useQueryMissingConditions = () => {
  const { request } = useGraphQLRequest();

  const fetchMissingConditions = async ({
    queryKey: [, { professional_id, job_id, assignment_id, position_ids, recommended }],
  }: QueryFunctionContext<[unknown, UseQueryMissingConditionsInput]>) => {
    const { conditions } = await request(GET_CONDITIONS, {
      input: {
        search: {
          missing_conditions: {
            assignment_id,
            for_application: false,
            for_on_boarding: false,
            for_premier_only: !!recommended,
            job_id,
            position_ids,
            professional_id,
          },
        },
      },
    });
    return conditions;
  };

  return fetchMissingConditions;
};

export type UseMissingConditionsOptions = Omit<
  UseQueryMissingConditionsInput,
  'professional_id'
> & {
  refetch?: boolean;
};

type UseMissingConditionsOptionsResult = {
  conditions: ICondition[];
  isLoading: boolean;
};

const useMissingConditions = ({
  refetch,
  ...args
}: UseMissingConditionsOptions = {}): UseMissingConditionsOptionsResult => {
  const { currentUser } = useCurrentUser();
  const onError = useHandleError();
  const fetchMissingConditions = useQueryMissingConditions();

  const enabled = !!currentUser?.professional;

  const { data, isLoading, isInitialLoading } = useQuery(
    [
      'missingConditions',
      {
        ...args,
        professional_id: currentUser?.professional.id,
      },
    ],
    fetchMissingConditions,
    {
      onError,
      enabled,
      refetchInterval: refetch ? DEFAULT_REFETCH_INTERVAL : undefined,
    },
  );

  return {
    conditions: data ?? [],
    isLoading: enabled ? isLoading : isInitialLoading,
  };
};

export default useMissingConditions;
