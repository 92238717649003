import React from 'react';
import { Link } from '@medely/ui-kit';
import { config } from '../../config';
import { HelpCenterLinkProps } from './interface';

export const HelpCenterLink = ({ configKey, url, text, isLarge }: HelpCenterLinkProps) => {
  let finalURL = url ?? '';
  if (configKey) {
    finalURL = config.help[configKey];
  }

  return (
    <Link url={finalURL} variant="text" size={isLarge ? 'l' : 'm'}>
      {text}
    </Link>
  );
};
