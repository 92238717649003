import { TaskCard } from '@medely/ui-kit';
import React from 'react';
import { TaskCardLayout } from './TaskCard';
import { useProNavigate } from '../../contexts';

export type ProPoolInviteProps = {
  locationId: number;
  facilityName: string;
  professionalQualificationId: number;
  plan: string;
};

export const ProPoolInvite = (props: ProPoolInviteProps) => {
  const { locationId, facilityName, professionalQualificationId, plan } = props;
  const { navigate } = useProNavigate();
  const goToPoolInvite = () =>
    navigate('ProPoolInvite', { locationId, facilityName, professionalQualificationId, plan });

  return (
    <TaskCardLayout>
      <TaskCard
        iconProps={{ name: 'calendar-check', color: 'tertiary' }}
        title="Pro Pool invite"
        body={facilityName}
        onClick={goToPoolInvite}
      />
    </TaskCardLayout>
  );
};
