import { IJob } from '@medely/types';
import { Box, HStack, Icon, Text } from '@medely/ui-kit';
import { HideFrom } from '@mp/components/HideFrom';
import React from 'react';
import { CancelationPolicy } from '../../CancelationPolicy';
import { JobDetailsListItem } from '../../JobDetailsListItem';
import { CancelShiftButton } from './CancelShiftButton';
import { UtmParamsType } from './interface';
import { AgencyName } from '@mp/components/agency';

type JobCancelationPolicyProps = {
  job: IJob;
  bookedOrInProgressOrCompleted: boolean;
  smallIcon?: boolean;
  utmParams?: UtmParamsType;
  agencyId?: number;
};

export const JobCancelationPolicy = ({
  job,
  bookedOrInProgressOrCompleted,
  smallIcon = false,
  utmParams,
  agencyId,
}: JobCancelationPolicyProps) => {
  const isCompany21779 = job.location.company_id === 21779;
  const cancellationProtectionInfoText = isCompany21779
    ? `This job pays a $40 cancellation fee if the facility cancels the job less than ${job.cancellation_fee_window} hours prior to the start time.`
    : `This job pays a ${job.cancellation_fee_charge_hours} hour cancellation fee if the facility cancels the job less than ${job.cancellation_fee_window} hours prior to the start time.`;

  return (
    <Box my={3}>
      <HideFrom saas>
        <CancelationPolicy />
      </HideFrom>
      {bookedOrInProgressOrCompleted && (
        <>
          <Box py={2}>
            <CancelShiftButton job={job} utmParams={utmParams} />
          </Box>
          <HideFrom agency saas>
            <JobDetailsListItem
              title="Cancellation Protection"
              icon={
                <Icon
                  name="light-shield-check"
                  iconSize={smallIcon ? '14px' : '20px'}
                  color="text.primary"
                  variant="custom"
                />
              }
            >
              <Text size="m" color="text.secondary">
                {cancellationProtectionInfoText}
              </Text>
            </JobDetailsListItem>
          </HideFrom>
        </>
      )}
      {!!agencyId && (
        <Box pt={2}>
          <HStack gap={1}>
            <Text size="l">Shift will be paid by </Text>
            <AgencyName agencyId={agencyId} />
          </HStack>
        </Box>
      )}
    </Box>
  );
};
