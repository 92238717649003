import React from 'react';
import { DeactivatedSaasCard } from './DeactivatedSaasCard';
import { CardLayout } from './CardLayout';
import { useCurrentUser } from '../../hooks/useCurrentUser';

type DeactivatedCardProps = {
  hasDisplayLinks?: boolean;
  isDeletionRequest?: boolean;
};

export const DeactivatedCard = ({
  hasDisplayLinks = false,
  isDeletionRequest = false,
}: DeactivatedCardProps) => {
  const { isSaas } = useCurrentUser();

  if (isSaas) {
    return <DeactivatedSaasCard />;
  }
  return (
    <>
      {isDeletionRequest ? (
        <CardLayout
          title="Deactivated"
          infoText="Your account has been deleted in response to your deletion request."
          hasDisplayLinks={hasDisplayLinks}
        />
      ) : (
        <CardLayout
          title="Deactivated"
          infoText="Your account has been deactivated for violation of Medely's Community Guidelines."
          hasDisplayLinks={hasDisplayLinks}
        />
      )}
    </>
  );
};
