import { gql } from 'graphql-request';

export const GET_LOCATION = gql`
  query Location($id: Int!) {
    location(id: $id) {
      id
      name
      state {
        abbreviation
      }
    }
  }
`;
