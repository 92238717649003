import { useCurrentUser } from '@mp/hooks';

import { graphql } from '@mp/graphql/generated';
import { useGraphQLQuery } from './useGraphQLRequest';
import { type ResultOf } from '@graphql-typed-document-node/core';

const DEFAULT_REFETCH_INTERVAL = 15000;

const MissingConditionsGql = graphql(`
  query GetMissingConditions($input: ConditionQueryInput) {
    conditions(input: $input) {
      id
      slug
      label
      payload
      condition_type
      display_category
      display_description
      condition_documents {
        id
        name
        category
        file
      }
      qualifications {
        display_category
        id
        input_source
        instructions
        long_name
        slug
        admin_view_only
        qualification_type
        status
        short_name
        conditions {
          id
        }
        qualification_attributes {
          id
          name
          field_type
          required
          data
          input_source
          active
          slug
        }
        default_verification_title
        qualification_automations {
          id
          description
          name
          loading_text
          is_active
          qualification_automation_attributes {
            id
            qualification_attribute {
              id
              field_type
              slug
              name
              data
              input_source
              active
            }
            required
          }
        }
      }
    }
  }
`);

type MissingConditionsGqlResult = ResultOf<typeof MissingConditionsGql>;
export type MissingCondition = NonNullable<MissingConditionsGqlResult['conditions']>[number];

type UseMissingConditionsOptionsResult = {
  conditions: MissingCondition[];
  isLoading: boolean;
  refetchMissingConditions: () => Promise<unknown>;
};

export type UseMissingConditionsInput = {
  assignment_id?: number;
  recommended?: boolean;
  job_id?: number;
  position_ids?: number[];
  refetch?: boolean;
};

export const useMissingConditionsCg = ({
  assignment_id,
  job_id,
  position_ids,
  recommended,
  refetch,
}: UseMissingConditionsInput = {}): UseMissingConditionsOptionsResult => {
  const { currentUser } = useCurrentUser();

  const enabled = !!currentUser?.professional;

  const {
    data,
    isLoading,
    isInitialLoading,
    refetch: refetchMissingConditions,
  } = useGraphQLQuery<MissingConditionsGqlResult>({
    enabled,
    operationName: 'GetMissingConditions',
    refetchInterval: refetch ? DEFAULT_REFETCH_INTERVAL : undefined,
    query: MissingConditionsGql,
    variables: {
      input: {
        search: {
          missing_conditions: {
            assignment_id: assignment_id,
            for_application: false,
            for_on_boarding: false,
            for_premier_only: !!recommended,
            job_id: job_id,
            position_ids: position_ids,
            professional_id: currentUser?.professional?.id,
          },
        },
      },
    },
  });

  return {
    conditions: data?.conditions ?? [],
    isLoading: enabled ? isLoading : isInitialLoading,
    refetchMissingConditions,
  };
};
