import React from 'react';
import { Text } from '@medely/ui-kit';
import { graphql } from '@mp/graphql/generated';
import { useGraphQLQuery } from '@mp/hooks/useGraphQLRequest';
import { ResultOf } from '@graphql-typed-document-node/core';
import { LoadingState } from '@mp/pages/application/components/LoadingState';

const AgencyGql = graphql(`
  query GetAgencyForName($id: Int!) {
    agency(id: $id) {
      name
    }
  }
`);

export const AgencyName = ({ agencyId }: { agencyId: number }) => {
  const { data, isLoading } = useGraphQLQuery<ResultOf<typeof AgencyGql>>({
    operationName: 'GetAgencyForName',
    query: AgencyGql,
    variables: {
      id: agencyId,
    },
    enabled: !!agencyId,
  });
  const agency = data?.agency;

  if (isLoading) {
    return <LoadingState />;
  }

  if (!agencyId) {
    return <></>;
  }

  return <Text size="l">{agency?.name}</Text>;
};
