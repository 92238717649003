import React from 'react';

import { IPayout } from '@medely/types';
import { Box, HStack, Icon, Label, Text, TextProps, VStack } from '@medely/ui-kit';
import { MedelyDateTime } from '@medely/date-time-tools';
import {
  centsToCurrency,
  getPayoutDateAsTitle,
  getPayoutDisplayStatus,
  getPayoutStatusColor,
  PayoutDisplayStatus,
  pluralize,
  shiftTotalsFromJobBillings,
} from '../../utils';

export type IPayoutForPayoutListItem = Pick<
  IPayout,
  | 'amount_cents'
  | 'expense_adjustments'
  | 'id'
  | 'job_billings'
  | 'status'
  | 'processed_on'
  | 'w2_submit_by_time'
>;

export type PayoutListItemProps = {
  variant: 'detailed' | 'simplified';
  payout: IPayoutForPayoutListItem;
  onClick?: () => void;
};

export const PayoutListItem = (props: PayoutListItemProps) => {
  return (
    <HStack
      py={2}
      alignItems="center"
      justifyContent="space-between"
      flex={1}
      onClick={props.onClick}
    >
      <VStack>
        <Label size="m">{getTitle(props)}</Label>
        <Text size="m" color="text.secondary">
          {getSubtitle(props)}
        </Text>
      </VStack>
      <HStack ml={2} alignItems="center">
        {getRightSide(props)}
        <Icon name="chevron-right" />
      </HStack>
    </HStack>
  );
};

const getTitle = ({ variant, payout }: PayoutListItemProps) => {
  const date = new MedelyDateTime(payout.processed_on || payout.w2_submit_by_time, {
    tz: 'system',
  });

  if (variant === 'simplified') {
    return `Payout ${date.format('M/d/yy')}`;
  }

  return getPayoutDateAsTitle(payout);
};

const getSubtitle = ({ variant, payout }: PayoutListItemProps) => {
  if (variant === 'simplified') {
    return payout.id;
  }

  const { shiftCount, hours } = shiftTotalsFromJobBillings(payout.job_billings);
  if (shiftCount > 0) {
    return `${pluralize(shiftCount, 'shift')} · ${pluralize(hours, 'hr')}`;
  }

  return pluralize(payout.expense_adjustments.length, 'adjustment');
};

const getRightSide = ({ variant, payout }: PayoutListItemProps) => {
  if (variant === 'simplified') {
    return null;
  }

  const displayStatus = getPayoutDisplayStatus(payout);
  let amountColor: TextProps['color'] = 'text.primary';
  if ([PayoutDisplayStatus.Canceled, PayoutDisplayStatus.Failed].includes(displayStatus)) {
    amountColor = 'text.disabled';
  } else if (payout.amount_cents < 0) {
    amountColor = 'state.error.secondary';
  }
  return (
    <>
      {displayStatus !== PayoutDisplayStatus.Paid ? (
        <Box mr={1}>
          <Text size="m" color={getPayoutStatusColor(displayStatus)}>
            {displayStatus.toString()}
          </Text>
        </Box>
      ) : null}
      <Text size="m" color={amountColor}>
        {centsToCurrency(payout.amount_cents)}
      </Text>
    </>
  );
};
