import { Box, OptionType, SingleSelectField, TextField } from '@medely/ui-kit';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { shouldDisplayCommentField } from '../cancelationUtils';

type CancellationFormProps = {
  options: OptionType[];
};

export const CancellationForm = ({ options }: CancellationFormProps) => {
  const cancellationReasonsId = useWatch({ name: 'cancellation_reason_id' });
  const displaysCommentField = shouldDisplayCommentField({ cancellationReasonsId, options });

  return (
    <Box testId="cancelation-content-cancellation-form" pb={2}>
      <SingleSelectField
        name="cancellation_reason_id"
        label="Reason for cancelling"
        options={options}
      />
      {displaysCommentField && (
        <Box pt={2}>
          <TextField
            name="other_reason"
            rows={3}
            multiline
            label="Comment"
            testId="cancelation-content-cancellation-form-other-reason"
          />
        </Box>
      )}
    </Box>
  );
};
