import { IJob } from '@medely/types';
import { useAnalytics } from '@medely/ui-kit';
import {
  FormValuesProps,
  UtmParamsType,
} from '@mp/components/jobs/jobDetailsComponents/jobCancelationPolicy/interface';
import { useProNavigate } from '@mp/contexts';
import { usePerDiemEventTracking } from '@mp/hooks';
import { useNetworkRequest } from '@mp/hooks/useNetworkRequest';
import { useMutation } from '@tanstack/react-query';

type UseCancelShiftButtonProps = {
  utmParams?: UtmParamsType;
  job: IJob;
  isAssignment: boolean;
};

export const useCancelShiftButton = ({
  utmParams,
  isAssignment,
  job,
}: UseCancelShiftButtonProps) => {
  const analytics = useAnalytics();
  const { trackCanceled } = usePerDiemEventTracking(analytics);
  const { navigate } = useProNavigate();
  const { axios } = useNetworkRequest();

  const createRequest = (values: FormValuesProps) => {
    const trackingData = {
      tracking_params: {
        utm_source: utmParams?.utm_source,
        utm_campaign: utmParams?.utm_campaign,
      },
    };
    const cancellationReasonData = {
      cancellation_reason_id: values.cancellation_reason_id,
      other_reason: values.other_reason,
    };
    const jobData = {
      cancel: {
        ...cancellationReasonData,
        ...trackingData,
      },
    };
    const assignmentData = {
      cancel: {
        ...cancellationReasonData,
      },
    };

    return isAssignment ? assignmentData : jobData;
  };

  const { mutate: cancel, isLoading } = useMutation(
    (values: FormValuesProps) => {
      return axios.post(`/v3/professional/jobs/${job.id}/cancel`, createRequest(values));
    },
    {
      onSuccess: ({ data }) => {
        trackCanceled(data.payload.id);
        navigate('Shifts');
      },
    },
  );
  return { cancel, isLoading };
};
