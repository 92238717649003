import React from 'react';
import { useHistory } from 'react-router';
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from '@medely/web-components';
import { Box, InfoBadge, Label } from '@medely/ui-kit';
import { ChevronRight } from '@medely/web-components/icons';

export enum OnboardingStepName {
  Address = 'address',
  Credentials = 'credentials',
  SkillsAssessments = ' skills_assessments',
  BackgroundCheck = 'background_check',
  UniversalBackgroundCheck = 'universal_background_check',
  DirectDeposit = 'direct_deposit',
  Experience = 'experience',
  WorkExperience = 'work_experience',
}

export type OnboardingStep = {
  name: OnboardingStepName;
  label: string;
  hint?: string;
  path: string;
  isComplete: boolean;
  isAwaitingReview: boolean;
  disabled?: boolean;
};

type OnboardingListProps = {
  steps: OnboardingStep[];
};

type OnboardingListStepBadgeProps = {
  step: OnboardingStep;
};

const OnboardingListStepBadge = ({ step }: OnboardingListStepBadgeProps) => {
  if (step.isComplete) {
    return (
      <Box mr={step.isAwaitingReview ? 1 : 0}>
        <InfoBadge
          color={step.isAwaitingReview ? 'info' : 'success'}
          label={step.isAwaitingReview ? 'In review' : 'Completed'}
        />
      </Box>
    );
  }

  if (step.hint) {
    return <InfoBadge color="default" label={step.hint} />;
  }

  return null;
};

const OnboardingList = ({ steps }: OnboardingListProps) => {
  const history = useHistory();
  const nextStep = steps.find((step) => !step.isComplete);

  if (!steps.length) {
    return null;
  }

  return (
    <>
      {nextStep && (
        <Box mb={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(nextStep.path)}
            data-testid="onboarding-list-get-started-button"
          >
            Get Started
          </Button>
        </Box>
      )}
      <List disablePadding>
        {steps.map((step, index) => {
          return (
            <>
              {Boolean(index) && (
                <ListItem disablePadding>
                  <Divider boxProps={{ sx: { width: '100%' } }} />
                </ListItem>
              )}
              <ListItem
                key={step.path}
                disablePadding
                data-testid={
                  step.isComplete
                    ? 'onboarding-list-completed-steps-item'
                    : 'onboarding-list-remaining-steps-item'
                }
              >
                <ListItemButton onClick={() => !step.disabled && history.push(step.path)}>
                  <ListItemText
                    primary={<Label size="m">{step.label}</Label>}
                    data-testid={
                      step.isComplete
                        ? 'onboarding-list-completed-steps-item-title'
                        : 'onboarding-list-remaining-steps-label'
                    }
                  />
                  <Box px={2}>
                    <OnboardingListStepBadge step={step} />
                  </Box>
                  {!step.isAwaitingReview && <ChevronRight />}
                </ListItemButton>
              </ListItem>
            </>
          );
        })}
      </List>
    </>
  );
};

export default OnboardingList;
