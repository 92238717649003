import { IJob } from '@medely/types';
import React from 'react';
import { CancelationContentText } from './CancelationContentText';
import { CancellationFormContainer } from './CancellationFormContainer';
import { UtmParamsType } from '../interface';

export const CancelationContent = ({
  job,
  closeDialog,
  utmParams,
}: {
  job: IJob;
  closeDialog: () => void;
  utmParams?: UtmParamsType;
}) => {
  return (
    <>
      <CancelationContentText job={job} />
      <CancellationFormContainer closeDialog={closeDialog} utmParams={utmParams} job={job} />
    </>
  );
};
