import { ConditionSatisfactionHelper } from '@medely/credentials-tools';
import { useProfessionalQualifications } from './useProfessionalQualifications';
import { useCurrentUser } from './useCurrentUser';
import type { ICondition } from '@medely/types';

export const useCombinedMissingConditions = (): {
  missingConditions: ICondition[];
  awaitingReviewConditions: ICondition[];
} => {
  const { currentUser } = useCurrentUser();
  const {
    allProfessionalQualifications,
    missingLicenses,
    missingCertifications,
    missingDocuments,
  } = useProfessionalQualifications();

  const allMissingConditions = [...missingLicenses, ...missingCertifications, ...missingDocuments];

  const missingConditions = allMissingConditions.filter((condition) => {
    const conditionHelper = new ConditionSatisfactionHelper({
      condition,
      professionalQualifications: allProfessionalQualifications,
      satisfiedConditionIds: currentUser?.professional?.satisfied_condition_ids ?? [],
    });
    return !conditionHelper.isAwaitingReview && !conditionHelper.isSatisfied;
  });

  const awaitingReviewConditions = allMissingConditions.filter((condition) => {
    const conditionHelper = new ConditionSatisfactionHelper({
      condition,
      professionalQualifications: allProfessionalQualifications,
      satisfiedConditionIds: currentUser?.professional?.satisfied_condition_ids ?? [],
    });
    return conditionHelper.isAwaitingReview;
  });

  return {
    missingConditions,
    awaitingReviewConditions,
  };
};
