import { Button, Form, HStack, SubmitButton } from '@medely/ui-kit';
import React from 'react';
import { CancellationForm } from './CancellationForm';
import { useGraphQLQuery } from '@mp/hooks/useGraphQLRequest';
import { ResultOf } from '@graphql-typed-document-node/core';
import { ContentLoader } from '@mp/components/ContentLoader';
import { CancellationReasonsGql } from '../cancellationQueries';
import { cancellationFormSchema } from './cancellationFormSchema';
import { FormValuesProps, UtmParamsType } from '../interface';
import { useCancelShiftButton } from '@mp/graphql/useCancelShiftButton';
import { IJob } from '@medely/types';
import { organizedOptions } from '../cancelationUtils';

type CancellationFormContainerProps = {
  closeDialog: () => void;
  utmParams?: UtmParamsType;
  job: IJob;
};

export const CancellationFormContainer = ({
  closeDialog,
  utmParams,
  job,
}: CancellationFormContainerProps) => {
  const isAssignment = !!job.assignment;
  const { cancel, isLoading: isCancelLoading } = useCancelShiftButton({
    isAssignment,
    job,
    utmParams,
  });
  const { data, isLoading } = useGraphQLQuery<ResultOf<typeof CancellationReasonsGql>>({
    operationName: 'GetCancellationReasonsForCancelationPolicy',
    query: CancellationReasonsGql,
    variables: {
      input: {
        filter: {
          role: 1,
        },
      },
    },
  });

  const cancellationReasons = data?.cancellationReasons || [];

  const options = cancellationReasons.map((c) => ({ value: c.id, label: c.label as string }));
  const otherId = options.find((o) => o.label.toLocaleLowerCase() === 'other')?.value || 15;

  const handleSubmit = (values: FormValuesProps) => {
    cancel(values);
  };

  return (
    <ContentLoader loading={isLoading}>
      <Form handleSubmit={handleSubmit} schema={cancellationFormSchema(otherId)}>
        <CancellationForm options={organizedOptions(options)} />
        <HStack justifyContent="flex-end" gap={2}>
          <Button onClick={closeDialog} testId="cancel-shift-dialog-back-button">
            Back
          </Button>
          <SubmitButton testId="cancel-shift-dialog-cancel-button" loading={isCancelLoading}>
            Cancel shift
          </SubmitButton>
        </HStack>
      </Form>
    </ContentLoader>
  );
};
