import {
  CredentialStatus,
  ICondition,
  ICredentialRejectedReason,
  IProfessionalQualification,
  IProfessionalQualificationAttribute,
  IQualification,
} from '@medely/types';
import {
  UseMutateFunction,
  UseMutateAsyncFunction,
  useQuery,
  QueryFunctionContext,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { GET_PROFESSIONAL_QUALIFICATIONS } from '../graphql';
import { useMissingConditions } from './useMissingConditions';
import { useCurrentUser } from './useCurrentUser';
import {
  useEditProfessionalQualification,
  useSubmitPendingProfessionalQualification,
  useSubmitProfessionalQualification,
} from './useProfessionalQualification';
import { GqlRequest, useNetworkRequest } from './useNetworkRequest';
import { ConditionSatisfactionHelper } from '@medely/credentials-tools';
import { useCallback } from 'react';
import isObject from 'lodash/isObject';

export const VIEWABLE_PQ_STATUSES: CredentialStatus[] = [
  'approved',
  'rejected',
  'expired',
  'review',
  'incomplete',
  // @ts-expect-error TODO: remove after merging https://github.com/Medely/medely-core-packages/pull/1156
  'incomplete_pending_review',
];

const DOCUMENT_TYPES = ['document', 'signed_document'];

export const DEFAULT_REFETCH_INTERVAL = 15000;

type TbProfessionalQualification = {
  id: number;
  qualification: Partial<IQualification>;
  status: CredentialStatus;
  professional_qualification_attributes: Partial<IProfessionalQualificationAttribute>[];
  credential_rejected_reason: Partial<ICredentialRejectedReason>;
};

export type TbScreening = {
  tbScreening: TbProfessionalQualification;
  tbBaseline: IProfessionalQualification;
  tbQuestionnaire: IProfessionalQualification;
};

export type CombinedTbProfessionalQualification =
  | IProfessionalQualification
  | TbProfessionalQualification;

type ProfessionalQualifications = {
  licenses: IProfessionalQualification[];
  missingLicenses: ICondition[];
  certifications: IProfessionalQualification[];
  missingCertifications: ICondition[];
  documents: IProfessionalQualification[];
  missingDocuments: ICondition[];
  allProfessionalQualifications: IProfessionalQualification[];
  allMissingConditions: ICondition[];
  hasMissingConditions: boolean;
  facilityOnboarding?: IProfessionalQualification;
  missingFacilityOnboarding: ICondition;
  completedFacilityOnboarding: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  submitProfessionalQualification: UseMutateAsyncFunction<
    AxiosResponse<any>,
    unknown,
    any,
    unknown
  >;
  submitPendingProfessionalQualification: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    any,
    unknown
  >;
  editProfessionalQualification: UseMutateFunction<AxiosResponse<any>, unknown, any, unknown>;
  refetch: () => Promise<unknown>;
};

export type UseProfessionalQualificationsArgs = {
  job_id?: number;
  position_ids?: number[];
  refetch?: boolean;
};

export const fetchProfessionalQualifications = async (
  queryContext: QueryFunctionContext,
  gqlRequest: GqlRequest,
): Promise<IProfessionalQualification[]> => {
  const {
    queryKey: [_entity, search],
  } = queryContext;
  const guardedSearch = isObject(search) ? search : {};
  const { professionalQualifications } = await gqlRequest(GET_PROFESSIONAL_QUALIFICATIONS, {
    input: { search: { statuses: VIEWABLE_PQ_STATUSES, not_archived: true, ...guardedSearch } },
  });
  return professionalQualifications;
};

export const useProfessionalQualifications = ({
  job_id,
  position_ids,
  refetch,
}: UseProfessionalQualificationsArgs = {}): ProfessionalQualifications => {
  const { currentUser } = useCurrentUser();
  const { gqlRequest } = useNetworkRequest();

  const enabled = !!currentUser;

  const { edit, isEditing } = useEditProfessionalQualification();
  const { submit, isSubmitting } = useSubmitProfessionalQualification();
  const { submitPending, isSubmittingPending } = useSubmitPendingProfessionalQualification();

  const { conditions: missingConditions, refetch: refetchMissingConditions } = useMissingConditions(
    { job_id, position_ids },
  );

  const {
    data: currentProfessionalQualifications = [],
    isInitialLoading: fetching,
    refetch: refetchProfessionalQualifications,
  } = useQuery(
    ['professionalQualifications'],
    (queryContext) => fetchProfessionalQualifications(queryContext, gqlRequest),
    { enabled, refetchInterval: refetch ? DEFAULT_REFETCH_INTERVAL : undefined },
  );

  const credentialDetails = parseConditionsAndQualifications(
    missingConditions,
    currentProfessionalQualifications,
    currentUser?.professional?.satisfied_condition_ids ?? [],
  );

  const refetchPQs = useCallback(
    () => Promise.all([refetchMissingConditions(), refetchProfessionalQualifications()]),
    [refetchMissingConditions, refetchProfessionalQualifications],
  );

  return {
    ...credentialDetails,
    allMissingConditions: missingConditions,
    hasMissingConditions: Boolean(missingConditions.length),
    allProfessionalQualifications: currentProfessionalQualifications,
    completedFacilityOnboarding:
      !!credentialDetails.facilityOnboarding &&
      credentialDetails.facilityOnboarding.status === 'approved',
    editProfessionalQualification: edit,
    isLoading: fetching || isSubmitting || isEditing || isSubmittingPending,
    isSubmitting,
    submitProfessionalQualification: submit,
    submitPendingProfessionalQualification: submitPending,
    refetch: refetchPQs,
  };
};

type GroupedPQs = {
  certifications: IProfessionalQualification[];
  documents: IProfessionalQualification[];
  signedDocuments: IProfessionalQualification[];
  facilityOnboarding?: IProfessionalQualification;
  licenses: IProfessionalQualification[];
};
const groupProfessionalQualifications = (
  currentProfessionalQualifications: IProfessionalQualification[],
): GroupedPQs => {
  const result: GroupedPQs = {
    certifications: [],
    documents: [],
    signedDocuments: [],
    facilityOnboarding: undefined,
    licenses: [],
  };

  currentProfessionalQualifications.forEach((pq) => {
    if (pq.qualification?.qualification_type === 'license') {
      result.licenses.push(pq);
      return;
    }

    if (['certification', 'q_and_a'].includes(pq.qualification?.qualification_type)) {
      result.certifications.push(pq);
      return;
    }

    if (DOCUMENT_TYPES.includes(pq.qualification.qualification_type)) {
      result.documents.push(pq);
      return;
    }

    if (pq.qualification.qualification_type === 'facility_onboarding') {
      result.facilityOnboarding = pq;
      return;
    }
  });

  return result;
};

const groupMissingConditions = (
  missingConditions: ICondition[],
  currentProfessionalQualifications: IProfessionalQualification[],
  currentSatisfiedConditionIds: number[],
) => {
  const result = {
    hasMissingCredentials: false,
    missingCertifications: [] as ICondition[],
    missingCredentials: [] as ICondition[],
    missingDocuments: [] as ICondition[],
    missingLicenses: [] as ICondition[],
    missingFacilityOnboarding: undefined as unknown as ICondition,
  };

  missingConditions.forEach((condition: ICondition) => {
    if (condition.qualifications && condition.qualifications[0].qualification_type === 'license') {
      result.missingLicenses.push(condition);
      return;
    }

    if (
      condition.qualifications &&
      condition.qualifications[0].qualification_type === 'certification'
    ) {
      result.missingCertifications.push(condition);
      return;
    }

    if (
      condition.qualifications &&
      DOCUMENT_TYPES.includes(condition.qualifications[0].qualification_type)
    ) {
      result.missingDocuments.push(condition);
      return;
    }

    if (condition.condition_type === 'facility_onboarding') {
      result.missingFacilityOnboarding = condition;
      return;
    }
  });

  // pro has missing conditions which will not be satisfied by the current professional qualifications they have uploaded
  const missingCredentials = [
    ...result.missingLicenses,
    ...result.missingCertifications,
    ...result.missingDocuments,
  ].filter((condition) => {
    const conditionHelper = new ConditionSatisfactionHelper({
      condition,
      professionalQualifications: currentProfessionalQualifications,
      satisfiedConditionIds: currentSatisfiedConditionIds,
    });
    return !conditionHelper.isAwaitingReview;
  });

  result.hasMissingCredentials = !!missingCredentials.length;

  return result;
};

const parseConditionsAndQualifications = (
  missingConditions: ICondition[],
  currentProfessionalQualifications: IProfessionalQualification[],
  currentSatisfiedConditionIds: number[],
) => {
  return {
    ...groupMissingConditions(
      missingConditions,
      currentProfessionalQualifications,
      currentSatisfiedConditionIds,
    ),
    ...groupProfessionalQualifications(currentProfessionalQualifications),
  };
};
