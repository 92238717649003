import { useCallback } from 'react';
import Rollbar, { LogArgument } from 'rollbar';

declare global {
  interface Window {
    Rollbar: Rollbar;
  }
}

let rollbar: Rollbar;

export const initRollbar = (config: { accessToken: string; environment: string }) => {
  if (!rollbar) {
    rollbar = new Rollbar({
      accessToken: config.accessToken,
      environment: config.environment,
    });
    window.Rollbar = rollbar;
    return rollbar;
  }

  return rollbar;
};

export const useRollbar = () => {
  const notifyRollbar = useCallback(
    (notifyObject: { errorLabel: string; args: LogArgument; level?: 'error' | 'warning' }) => {
      const { errorLabel, args, level } = notifyObject;

      if (!rollbar) {
        return;
      }

      if (process.env.NODE_ENV === 'production') {
        if (level === 'warning') {
          rollbar.warning(errorLabel, args);
        } else {
          rollbar.error(errorLabel, args);
        }
      }
    },
    [],
  );

  return { notifyRollbar, rollbar };
};
