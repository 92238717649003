import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { GraphQLClient, RequestDocument } from 'graphql-request';
import { firebaseAuth } from '../firebase';

export const gqlRequest = async <T = any>(
  query: RequestDocument | TypedDocumentNode,
  variables = {} as any,
) => {
  const token = await firebaseAuth.currentUser?.getIdToken();
  const client = new GraphQLClient(
    `${process.env.REACT_APP_GRAPHQL_URL || process.env.EXPO_PUBLIC_GRAPHQL_URL}/graphql`,
    {
      headers: token
        ? {
            authorization: `Bearer ${token}`,
          }
        : {},
    },
  );

  return client.request<T>(query, variables);
};
