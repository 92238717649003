import { validationMessages, yup } from '@medely/ui-kit';

export const personalValidationSchema = yup.object({
  nickname: yup.string().nullable(),
  phone_number: yup.string().phone().required(validationMessages.phone.required),
  address_first: yup.string().required('Required'),
  address_second: yup.string().nullable(),
  address_city: yup.string().required('Required'),
  state_id: yup.number().required('Required'),
  address_zip: yup.number().zip().required('Required'),
  emergency_first_name: yup.string().nullable(),
  emergency_last_name: yup.string().nullable(),
  emergency_phone_number: yup.string().phone().nullable(),
});
