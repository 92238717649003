import { graphql } from '@mp/graphql/generated';

export const StatesGql = graphql(`
  query GetStatesForPersonalForm($input: StateQueryInput) {
    states(input: $input) {
      id
      name
    }
  }
`);

export const CurrentUserGql = graphql(`
  query GetCurrentUserForPersonalForm($id: Int!, $location_ids: [Int!]!) {
    me {
      id
      address_first
      address_second
      address_city
      state_id
      address_zip
      phone_number
      professional {
        id
        nickname
      }
    }
  }
`);

export const EmergencyContactGql = graphql(`
  query GetEmergencyContactForPersonalForm($input: EmergencyContactQueryInput) {
    emergencyContacts(input: $input) {
      id
      first_name
      last_name
      phone_number
      professional_id
    }
  }
`);
