import { IJob } from '@medely/types';
import { OptionType } from '@medely/ui-kit';
import moment from 'moment';

export const LATE_CANCELLATION_WINDOW_HOURS = 24;

type CalculateLateCancellationProps = {
  bookedAt: IJob['booked_at'];
  currentStartsTime: IJob['current_starts_time'];
  jobAssignment: IJob['assignment'] | null;
  updatedInCancellationWindow: IJob['updated_in_cancellation_window'];
};

export const calculateLateCancellation = ({
  bookedAt,
  currentStartsTime,
  jobAssignment,
  updatedInCancellationWindow,
}: CalculateLateCancellationProps) => {
  const isBookedWithin30mins = moment().isBefore(moment(bookedAt).add(30, 'minutes'));
  const startsWithinLateCancellationPeriod = moment().isAfter(
    moment(currentStartsTime).subtract(LATE_CANCELLATION_WINDOW_HOURS, 'hours'),
  );
  const isFromAssignment = !!jobAssignment;
  const showLateCancellationWarning =
    startsWithinLateCancellationPeriod &&
    !isBookedWithin30mins &&
    !isFromAssignment &&
    !updatedInCancellationWindow;

  return {
    isBookedWithin30mins,
    isFromAssignment,
    showLateCancellationWarning,
  };
};

export const organizedOptions = (options: OptionType[]) => {
  const otherItem = options.find((o) => o.label.toLocaleLowerCase() === 'other');
  if (otherItem) {
    const index = options.indexOf(otherItem);
    options.splice(index, 1);

    options.push(otherItem);
  }
  return options;
};

type ShouldDisplayCommentFieldProps = {
  cancellationReasonsId: undefined | number;
  options: OptionType[];
};

export const shouldDisplayCommentField = ({
  cancellationReasonsId,
  options,
}: ShouldDisplayCommentFieldProps) => {
  if (!cancellationReasonsId || options.length === 0) {
    return false;
  }
  const reason = options.find((o) => o.value === Number(cancellationReasonsId));
  return reason?.label?.toLowerCase() === 'other';
};
